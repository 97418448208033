@import '../base/common';
@import '../components/section-header';
@import '../components/button/pill';
.ready-additions {
  margin-bottom:scale-vw-mobile(100px);
  .o-darken__overlay {
    &::before {
      opacity: .8;
    }
  }

  .o-grid:not(.o-list--leading-zero){
    display:grid;
    grid-template-columns:1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
  }

  .o-list--leading-zero{
    display:grid;
    grid-template-columns:1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    .o-grid__item{
      width:100%;
      display:flex;
      flex-direction:row;
      background-color:$color-burnt-cacao;
      padding:scale-vw-mobile(10px);
      a{
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content: space-between;
        width:100%;
        text-decoration:none;
      }
    }
  }
}

.ready-additions__products {
  .c-product-category-grid > .o-grid__item {

    width: 100%;

  }
}

.ready-additions-slice-guide {
  position: relative;

  background: #F0E5D8;
  color: #000;

  padding: 80px 40px 55px 40px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: solid transparent;
    pointer-events: none;

    border-width: 2.1875em;
    border-top-color: #000;
    transform: translate(-50%, 0);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: solid transparent;
    pointer-events: none;

    border-width: 0.40625em;
    border-top-color: $color-gold-1;
    transform: translate(-50%, 0);
  }

  &__inner {
    position: relative;
  }

  .c-heading {
    color: #000;
  }

  &__item {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      height: 1px;
      width: 50%;
      left: 50%;
      bottom: 0;
      transform: translate3d(-50%, 0, 0);
      border-bottom:  2px dotted $color-brand-red;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  @include mq(md) {

    &__item {
      position: relative;
      &::after {
        display: none;
      }
    }

    &__inner {
      // &::before {
      //   content: '';
      //   position: absolute;
      //   height: 1px;
      //   width: 100%;
      //   // background: $color-gold-1;
      //   top: 50%;
      //   left: 0;
      //   border-bottom:  2px dotted $color-brand-red;
      // }

      // &::after {
      //   content: '';
      //   position: absolute;
      //   height: 100%;
      //   width: 1px;
      //   // background: $color-gold-1;
      //   left: 50%;
      //   top: 0;
      //   bottom: auto;
      //   border-left:  2px dotted $color-brand-red;
      // }
    }
  }
}
