@use "sass:math";

@include o-video();
@include o-video__wrapper();
@include o-video__element();
@include o-video__close();

$desktop-bp: '680';

.o-video {

  --video-play-btn-color: var(--color-bone);
  --video-aspect-ratio: 326 / 398;

  @include mq($desktop-bp) {
    --video-aspect-ratio: 1280 / 720
  }

  position: relative;
}

.o-video__content {
  padding-left: var(--page-margins-outer);
  padding-right: var(--page-margins-outer);

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.o-video__background {
  aspect-ratio: var(--video-aspect-ratio);

  grid-row: 1;
  grid-column: 1;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  position: relative;

  &::after {
    content: '';

    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);
  }


  video, img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
  }

  video {
    z-index: 1;
  }
}


.o-video__details {

  grid-row: 1;
  grid-column: 1;

  width: 100%;
  height: 100%;

  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  padding: 18px;
  @include mq($desktop-bp) {
    padding: scale-1200(54px);
  }
}

.o-video__subheading {
  @include heading--md();
  margin-bottom: scale-1200(8px);
}

.o-video__heading {
  @include body--xl();
  margin-bottom: scale-1200(12px);
}

.o-video__play-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;

  width: scale-1200(57.5px);
  height: scale-1200(57.5px);

  text-decoration: none;

  &::after {
    content: '';

    width: 100%;
    height: 100%;

    border: 1px solid var(--color-bone);

    transform: rotate(-45deg);
    transition: transform .2s var(--motion-easing);

    position: absolute;
    top: 0;
    left: 0;
  }

  span {
    @include cta--xs();
    color:  var(--color-bone);
  }

  &:hover, &:focus {

    outline: none;

    &::after {
      transform: scale(1.1) rotate(-45deg);
    }
  }
}

// scss-lint:enable ImportantRule
// scss-lint:enable QualifyingElement
