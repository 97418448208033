$animation-duration: 1000ms !default;

@keyframes come-in {
  to {
    opacity: 1;
  }
}

@keyframes come-in-scale {
  to {
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
}

@keyframes slight-bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-6px);
  }
  60% {
    transform: translateY(-3px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-12px);
  }
  60% {
    transform: translateY(-6px);
  }
}
