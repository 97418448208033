.accordion {

}

.accordion-item__content-container {
  max-height: 0;
  overflow: hidden;
  will-change: max-height;
  pointer-events: none;

  .active & {
    pointer-events: auto;
  }
}
