/**
 * List object.
 * @param  {String}  $at-breakpoint
 *
 * ## Sass
 *
 * ```scss
 * @include o-list();
 * @include o-list('sm');
 * ```
 *
 * ## Markup
 *
 * ```html
 * <label class="c-checkbox">
 *   <input class="c-checkbox__input" type="checkbox" name="form_field[]" value="form_value">
 *   <span class="c-checkbox__box"></span>
 *   <span class="c-checkbox__copy">Label</span>
 * </label>
 * ```
 */
$ch-primary-color  : $color-brand-gold !default;
$ch-disabled-color : $color-gray-2 !default;
$ch-align-items    : center;
$ch-size           : 1rem;
$ch-border-width   : 1px;
$ch-border-radius  : 0;

.c-checkbox {
  display: flex;
  flex-wrap: nowrap;
  align-items: $ch-align-items;
  cursor: pointer;

  @include hover {
    .c-checkbox__box {
      border-color: $ch-primary-color;
    }
  }
}

.c-checkbox__input {
  display: none;

  &:checked + .c-checkbox__box {
    border-color: $ch-primary-color;
    background-image: svg("<path fill='#{$ch-primary-color}' d='M27.1 60.1l-22-22 8.6-8.5L27.1 43l31.2-31.1 8.6 8.5z'/>", 72, 72);
  }
}

.c-checkbox__box {
  display: inline-block;
  flex: 0 0 auto;
  width: $ch-size;
  height: $ch-size;
  border: $ch-border-width solid $ch-disabled-color;
  border-radius: $ch-border-radius;
  background: center / 10px 10px no-repeat;
  vertical-align: middle;
}

.c-checkbox__copy {
  user-select: none;
}

// Disabled State
.c-checkbox.is-disabled {
  cursor: default;

  @include hover {
    .c-checkbox__box {
      border-color: $ch-disabled-color;
    }
  }

  .c-checkbox__input {
    display: none;

    &:checked + .c-checkbox__box {
      border-color: $ch-disabled-color;
      background-image: none;
    }
  }

  .c-checkbox__copy {
    opacity: 0.5;
  }
}



// ------------------------------
// Variations
// ------------------------------

.c-checkbox--block {
  .c-checkbox__box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: box-shadow 0.3s ease;
    border: 1px solid transparent;
    background: #fff;
  }

  // Checked State
  .c-checkbox__input:checked + .c-checkbox__box {
    border-color: transparent;
    background: #7bbe47;

    .c-sub {
      color: #fff;
    }

    .c-icon {
      color: #fff;
    }
  }

  // Hover State
  // scss-lint:disable DeclarationOrder
  @include hover {
    .c-checkbox__input:not(:checked) + .c-checkbox__box {
      border-color: #fff;
    }
  }
  // scss-lint:enable DeclarationOrder

  // Disabled State
  // .c-checkbox.is-disabled {
  //   @include hover {
  //     .c-checkbox__box {
  //     }
  //   }
  //   .c-checkbox__input:checked + .c-checkbox__box {
  //   }
  // }
}
