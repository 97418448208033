.x-up-recipe {
  z-index: 3;

  @media (min-width: 680px) and (min-height: 870px) {
    align-self: center;

    grid-row: 1;

    // max-width: 3711px;
    width: min-content;
    height: 100%;
  }

  .o-preserve-aspect-ratio--1x1\@680 {
    @media (min-width: 680px) and (min-height: 870px) {
      padding: 0 0 100% 0;
    }
  }

  .x-up-recipe__grid-container {
    padding-top: scale-vw-mobile(40px);

    @media (min-width: 680px) and (min-height: 870px) {
      display: flex;
      align-items: center;
      height: 100%;

      padding-top: var(--header-full-height);
      padding-bottom: 0;
    }

    @media (min-width: 680px) and (min-height: 900px) {
      padding: var(--header-full-height) 0 scale-1200(114px);
    }
  }

  .x-up-recipe--wrapper {

    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: scale-1200(20px);
    grid-template-areas:
      "lg-recipe";

    justify-items: center;
    @include main-container();

    @media (min-width: 680px) and (min-height: 870px) {

      // max-width: 3711px;

      padding-left: 0;

      grid-row-gap: scale-1200(50px);
      grid-template-columns: scale-1200(758px) scale-1200(530px) scale-1200(530px);
      grid-template-rows: scale-1200(355px) scale-1200(355px);
      grid-template-areas:
        "lg-recipe sm-recipe-2 sm-recipe-3"
        "lg-recipe sm-recipe-4 sm-recipe-5";
      grid-column-gap: scale-1200(50px);

      &.threeUpRecipes {
        // max-width: 1172px;
        grid-template-columns: scale-1200(607px) scale-1200(530px);
        grid-template-areas:
          "lg-recipe sm-recipe-2"
          "lg-recipe sm-recipe-3";
      }
    }

    .recipe {
      overflow: hidden;

      background-color: #fff;
      border-radius: 20px;
      width: calc(100% - 7.8vw);

      @media (min-width: 680px) and (min-height: 870px) {
        width: 100%;
        grid-area: lg-recipe;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        z-index: 6;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      }

      .caption {

        display: flex;
        flex-direction: row;
        z-index: 9;
        width: 100%;
        height:100%;
        justify-content: space-between;
        align-items: flex-end;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        padding: scale-vw-mobile(16px);

        @media (min-width: 680px) and (min-height: 870px) {
          padding: scale-1200(50px);
        }

        h3 {
          color: $color-bone;

          @include body--md();

          @media (min-width: 680px) and (min-height: 870px) {
            @include heading--md();
          }

          width: 75%;
        }

        a {
          @include full-area-cta();
        }

      }

      &.recipe-1 {

        @media (max-width: 680px) or (max-height: 680px) {
          width: 100%;
          margin-bottom:scale-vw-mobile(20px);
          .o-preserve-aspect-ratio {
            padding: 0 0 100%;
          }
        }

        .social-icon {
          z-index: 4;
          background-color: $color-brand-gold;
          border-bottom-left-radius: 20px;
          padding: 20px;
          position: absolute;
          top: 0;
          right: 0;

          a {
            color: white;

            .c-icon {
              width: 30px;
              height: 30px;
            }
          }

          .handle {
            color: $color-burnt-cacao;
          }
        }
      }

      @media (min-width: 680px) and (min-height: 870px) {
        @for $i from 2 to 6 {
          &.recipe-#{$i} {
            grid-area: sm-recipe-#{$i};

            .caption {
              padding: scale-1200(30px);

              h3 {
                @include body--md();
              }
            }
          }
        }
      }
    }
  }
}
