@media print {

  // nav
  .header__navigation-drawer {
    display: none;
  }

  .c-header {
    position: static;
    border-bottom: 0;

    &::before {
      content: none;
    }
  }

  .recipe-detail__content {
    padding-top: 0;
  }

  // sticky nav unstick
  .c-header--sticky {
    position: static;
    padding-top: 0;
    padding-bottom: 0;
    height: auto;

    .c-header__nav {
      height: auto;
    }
  }

  // inner/main content areas
  main,
  #main {
    padding-top: 10px;
    padding-bottom: 0;
    border-bottom: 0;
    min-height: inherit;
  }

  .o-section__content {
    padding-top: 20px;
    margin-bottom: 0;
  }

  // hero
  .c-hero__scroll-button,
  .c-header__item--menu {
    display: none;
  }

  .c-hero__preserve-aspect-ratio {
    padding: 0;
  }

  .c-hero {
    max-height: none;
  }

  hr {
    display: none;
  }

  // footer
  #footer {
    display: none;
  }
  .c-footer.is-fixed {
    position: static;
  }
  .c-footer__placeholder {
    display: none;
  }


  // white text



  // images



  // margin and padding
  .u-margin-bottom-xs {
    margin-bottom: 10px;
  }
  .u-padding-bottom-sm {
    padding-bottom: 10px;
  }
  .u-margin-top-md {
    margin-top: 20px;
  }

  // product detail
  .c-product-recommendations,
  .c-product-detail__sidebar,
  .c-product-grid__figure {
    display: none;
  }

  .c-product-detail__content {
    margin-bottom: 10px;
    min-height: inherit;
    font-size: 14px;

    .o-grid--inner-border--sm > .o-grid__item {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  [href="#nutrition-modal"] {
    display: none;
  }

  #nutrition-modal {
    display: block;
    opacity: 1;
    visibility: visible;
    position: static;

    .c-modal__close {
      display: none;
    }
  }

  .c-health-attributes {
    margin-top: 0;
    margin-bottom: 0;
  }

  // recipe detail
  ._recipe-image {
    display: none;
  }
  .recipe-detail{
    color:black;
  }
  .o-sidebar-layout__sidebar {
    display: none;
  }

  .o-list--ingredients .o-list__item,
  .o-list--leading-zero .o-list__item--instructions {
    margin-bottom: 0;
  }

  // addthis
  #at-expanded-menu-v2 {
    display: none;
  }

  .disable-print {
    display: none !important;
  }

  .recipe-detail__meta-share {
    display: none;
  }

  .recipe-detail__details {
    margin-top: 100px;
  }
}

.enable-print {
  display: none !important;
  @media print {
    display: block !important;
  }
}
