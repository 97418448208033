.post-detail-full {

  .header__navigation-btn:not(.navigation-open) {
    @include mq('lg') {
      --btn-pill-border: transparent;
      --btn-pill-icon-color: #{$color-burnt-cacao};
      --btn-pill-txt-color: #{$color-burnt-cacao};
      --btn-pill-background: #{$color-bone};
    }
  }

  .nest-wrapper {
    position: relative;
    height: 100% !important;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      height: 22.0238095vw;
      width: 100%;
      background: linear-gradient(180deg, rgba(135, 117, 69, 1) 0%, rgba(135, 117, 69, 0) 100%);
      mix-blend-mode: multiply;
      z-index: 2;
      opacity: 1;
      pointer-events: none;
    }
  }
}

.content-hub--post-detail {
   .content-hub__article-drawer .drawer-content {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      height: 22.0238095vw;
      width: 100%;
      background: linear-gradient(180deg, rgba(135, 117, 69, 1) 0%, rgba(135, 117, 69, 0) 100%);
      background-blend-mode: multiply;
    }
  }

  &:not(.post-detail-full) {
    .content-hub__article-drawer {
      @include mq('lg') {
        // Adjust variables that no longer need to account for the scroll bar width
        margin-right: 0;
        width: calc(100vw - var(--content-hub-back-button-width));
      }
    }
  }
}

.nest-wrapper {
  // override scroll behavior
  height: 100% !important;
}

.content-hub--post-wrapper {

  background-color:$color-bone;
  color:$color-burnt-cacao;

  @include body--md();
  display: flex;
  flex-direction: column;

  &.content-hub-post--vertical {
    flex-direction: column !important;
  }

  // padding-right: scale-1200(70px);

  &.content-hub--post-related {
    @media (min-height: 870px) and (min-width: 680px) {
      padding-right: 0;
    }
  }

  @media (min-width: 680px) and (min-height: 870px) {
    padding-left: scale-1200(106px);
    height: 100%;
    max-width: none;

    flex-direction: row;
    flex-wrap: nowrap;

    height:100vh;
    overflow-y: hidden;
  }

  @media (max-height: 870px) and (min-width: 1200px) {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  h1{
    @include cta--lg();
  }

  h2{
    span.u-type-subscripts__symbol--numeric {
      font-size: .79em;
    }
    @include heading--sm();
    color:$color-brand-gold;
    margin: scale-1200(11px) 0 scale-1200(18px);

    @media (min-width: 680px) and (min-height: 870px) {
      margin: scale-1200(42px) 0 scale-1200(25px);
    }
  }

  .x-up-recipe + .large-recipe {

    margin-top: calc(-1 * scale-1200(60px));

    // @include mq('lt-680'){
    //   margin-top:calc(-1 * scale-1200(60px));
    // }

    @media (min-width: 680px) and (min-height: 870px) {
      margin-top: 0;
      margin-left: calc(-1 * scale-1200(60px));
    }
  }

  .x-up-recipe + .x-up-recipe {

    margin-top: scale-1200(20px);

    @media (min-width: 680px) and (min-height: 870px) {
      margin-top: 0;
      // margin-left: scale-1200(60px);
    }
  }

  // .x-up-recipe + .next-post--container {

  //   // margin-top: scale-1200(20px);

  //   // @include mq('lt-680'){
  //   //   margin-top: scale-1200(20px);
  //   // }

  //   // @media (min-width: 680px) and (min-height: 870px) {
  //   //   margin-top: 0;
  //   //   margin-left: scale-1200(60px);
  //   // }
  // }
}

.content-hub--post-container {

  align-self:center;

  padding-top: calc(var(--header-full-height) + #{scale-vw-mobile(38px)});
  margin-bottom: scale-vw-mobile(40px);

  .content-hub--post {
    text-align:center;
    @include main-container();
    width:100%;
  }

  @media (min-width: 680px) and (min-height: 870px) {
    grid-row: 1;
    margin-right: scale-1200(240px);
    padding-top: 0;
    margin-bottom: 0;

    .content-hub--post {
      margin-top:auto;
      padding:0;
      min-width:388px;
      width:100%;
      text-align:left;
    }
  }
}

.c-button--pill{
  @include button--pill($dark: true);
}
