.careers__footer-section{
  position:relative;
  &-content{
    margin:0 auto;
    padding: scale-1200(83px) 0 scale-1200(109px);
    z-index:2;
    position:relative;
    text-align:center;

    h5{
      @include heading--sm();
      color: $color-brand-gold;
    }

    h6{
      padding: scale-1200(26px) 0 scale-1200(24px);
      @include heading--lg();

    }

    p{
      max-width:34vw;
      min-width: 283px;
      margin:0 auto scale-1200(40px);
    }

  }
  // &:after{
  //   content:'';
  //   position:absolute;
  //   z-index:0;
  //   top:0;
  //   left:0;
  //   bottom:0;
  //   right:0;
  //   background-color: $color-burnt-cacao;
  //   mix-blend-mode: multiply;
  //   z-index:1;
  // }
  img{
    z-index:0;
  }
  a{
    @include button--pill();
  }
}
