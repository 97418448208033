
@mixin circle($size: null, $size-hover: null) {
  // TODO: Should circle come with a relative position by default?
  // position: relative;
  // fix flickering in safari:
  -webkit-transform-style: preserve-3d;

  > * {
    // fix flickering in safari:
    -webkit-backface-visibility: hidden;
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: transform 200ms ease;
    border: 2px solid rgba($color-brand-gold, 0.5); // $color-brand-gold;
    border-radius: 50%;
    content: '';
    pointer-events: none;

    @if ($size) {
      width: $size;
      height: $size;
    } @else if ($size and $size-hover) {
      width: percentage(math.div($size, $size-hover));
      height: percentage(math.div($size, $size-hover));
    }
  }
}

// TODO: Can we decide on a single circle hover convention? (E.g., always 50% opacity)
@mixin circle-hover($size: null, $size-hover: null) {
  @include hover('is-active') {
    &::after {
      @if ($size and $size-hover) {
        transform: translate(-50%, -50%) scale(#{math.div($size-hover, $size)});
      }
      background: rgba($color-gold-3, 0.1);
    }
  }
}
