.large-recipe {
  // background-color: $color-bone;

  @media (min-width: 680px) and (min-height: 870px) {
    grid-row: 1;
    height: 100vh;
    width: 96vw;
    max-width:1577px;
    // overflow-y: hidden;
    // margin-right:scale-1200(270px);
  }

  z-index: 1;

  .recipe-wrap {

    display: grid;

    grid-template-columns: 1fr;
    grid-row-gap: 20px;

    @media (min-width: 680px) and (min-height: 870px) {
      height: 100vh;
      grid-template-rows: 1fr;
      grid-template-columns: scale-1200(1024px) scale-1200(479px);
      grid-column-gap: scale-1200(70px);
      grid-row-gap:auto;
    }

    .recipe-image {
      @media (min-width: 680px) and (min-height: 870px) {
        grid-row: 1;
        grid-column: 1 / span 1;
        overflow:hidden;
      }
    }

    .recipe-data {

      @media (min-width: 680px) and (min-height: 870px) {
        grid-row: 1;
        grid-column: 2 / span 1;
        height: 100vh;
      }

      padding: 0 18px 0 0;


      display:grid;
      align-items:flex-end;
      .content{
        margin-left:scale-vw-mobile(23px);
        width:77%;

        @media (min-width: 680px) and (min-height: 870px) {
          margin-left:0;
        }

        p {
          margin: scale-1200(26px) 0 scale-1200(40px);
        }

        a, button {
          margin-bottom: scale-vw-mobile(52px);
          @media (min-width: 680px) and (min-height: 870px) {
            margin-bottom: scale-1200(113px);
          }
        }
      }
    }
  }
}
