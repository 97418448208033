@mixin main-header-container() {
  padding-left: scale-vw-mobile($main-header-margins--mobile);
  padding-right: scale-vw-mobile($main-header-margins--mobile);

  @include mq('xl') {
    padding-left: scale-1200($main-header-margins);
    padding-right: scale-1200($main-header-margins);
  }
}

@mixin main-container() {
  padding-left: scale-vw-mobile($page-margins--mobile);
  padding-right: scale-vw-mobile($page-margins--mobile);

  @include mq('xl') {
    padding-left: scale-1200($page-margins--outer);
    padding-right: scale-1200($page-margins--outer);
  }
}

@mixin page-padding--inner() {
  padding-left: scale-vw-mobile($page-margins--mobile);
  padding-right: scale-vw-mobile($page-margins--mobile);

  @include mq('xl') {
    padding-left: scale-1200($page-margins--inner);
    padding-right: scale-1200($page-margins--inner);
  }
}

@mixin page-padding--left-inner() {
  padding-left: scale-vw-mobile($page-margins--mobile);

  @include mq('xl') {
    padding-left: scale-1200($page-margins--outer);
  }
}

@mixin page-padding--right-inner() {
  padding-left: scale-vw-mobile($page-margins--mobile);

  @include mq('xl') {
    padding-left: scale-1200($page-margins--outer);
  }
}

@mixin page-padding--left-outer() {
  padding-left: scale-vw-mobile($page-margins--mobile);

  @include mq('xl') {
    padding-left: scale-1200($page-margins--outer);
  }
}

@mixin page-padding--right-outer() {
  padding-left: scale-vw-mobile($page-margins--mobile);

  @include mq('xl') {
    padding-left: scale-1200($page-margins--outer);
  }
}

@mixin section-header-container() {
  padding-left: scale-vw-mobile($section-header--mobile);
  padding-right: scale-vw-mobile($section-header--mobile);
  @include mq('xl') {
    padding-left: scale-1200($page-margins--inner);
    padding-right: scale-1200($page-margins--inner);
  }
}

