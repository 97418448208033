$c-nutrition-color: $color-gray-3;
$c-nutrition-font:  helvetica neue, helvetica, arial, sans-serif;

$c-nutrition-summary__item-border-radius: 3px;
$c-nutrition-summary__item-colors:
  calories    #901a1c,
  total-fat   #ab965d,
  cholesterol #00396c,
  sodium      #006432,
  protein     #430055;


.nutrition-panel {

  width: 100%;
  height: calc(100dvh - var(--header-height));
  top: var(--header-height);
  padding: 0;

  .c-modal__close {
    right: auto;
    left: auto;
    top: 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    column-gap: 12px;
    width: auto;
    height: auto;
    @include cta--sm();

    &:focus {
      text-decoration: none;
    }

    &:focus-visible {
      outline: none;
      text-decoration: none;
      // text-decoration: underline;
    }
  }

  .c-icon--close  {
    width: 11px;
    height: 11px;
    display: block;
    margin: 0;
  }

  .c-modal__content-container {
    width: 100%;
    background: #000;
    min-height: 100%;
  }

  .c-modal__content {
    width: 100%;
    padding-left: var(--page-margins-inner);
    padding-right: var(--page-margins-inner);
    background: none;
  }

  .c-health-attributes {
    display: grid;
    grid-template-columns: 100%;
    column-gap: 17px;
    row-gap: 17px;
    margin-bottom: scale-1200(30px);

    @include mq('md') {
      grid-template-columns: 1fr 1fr;
    }
  }

  .c-health-attribute {
    display: flex;
    align-items: center;
    @include sans-serif--md();
    column-gap: scale-1200(12px);
  }
}

.nutrition-panel__content {
  display: grid;

  grid-template-columns: 100%;
  row-gap: 53px;
  grid-template-areas:
    "nutrition"
    "product-qp";

  @include mq('md') {
    grid-template-columns: 1fr minmax(20px, 96px) 1fr;
    grid-template-areas:
      "product . ."
      "product-qp . nutrition";
  }

  @include mq('xl') {
    grid-template-columns: 1.12fr minmax(20px,60px) 1.2fr minmax(20px,47px) 1fr;
    grid-template-areas:
      "product . product-qp . nutrition";
  }
}

.nutrition-panel__product {
  grid-area: product;

  display: none;
  @include mq('md') {
    display: block;
  }

  h1 {
    @include heading--lg();
    @include mq('xl') {
      margin-bottom: scale-1200(80px);
    }
  }

  > img {
    display: none;
    @include mq('xl') {
      display: block;
      max-width: 88.5%;
    }
  }
}

.nutrition-panel__quality-promise {
  grid-area: product-qp;

  .c-icon--health {

    height: 48px;
    width: 48px;

    svg {
      stroke: none;
      fill: none;
    }
  }
}

.nutrition-panel__quality-promise-copy {
  margin-bottom: scale-1200(54px);
}

.nutrition-panel__quality-promise-cta {
  margin-bottom: scale-1200(70px);
}

.nutrition-panel__disclaimer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  > span {
    display: block;
    @include body--sm();
    color: $color-brand-legal;
  }
}

.nutrition-panel__label {
  grid-area: nutrition;
  display: flex;
  justify-content: center;
}

.c-nutrition {

  justify-self: center;
  height: min-content;

  > h2 {
    @include heading--og-md();
    padding-bottom:11px;
  }

  nav {
    @include body--sm();
    color: $color-brand-gold;
    a {
      text-decoration:none;
    }
  }

  background-color: #1a1917;
  -webkit-font-smoothing: auto;
  width: 90%;
  max-width: 335px;
  padding: scale-1200(44px) scale-1200(30px) scale-1200(48px);

  @include mq('md') {
    justify-self: start;
    width: 100%;
  }
}

.c-nutrition__inner {
  border: 1px $c-nutrition-color solid;
  padding: 3px 7px 4px;
}

.c-nutrition-summary {
  width: 100%;
  font-family: $c-nutrition-font;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.c-nutrition-summary__item {
  background: $color-gray-3;
  padding: 3px;
  vertical-align: top;
}

.c-nutrition-summary__item--header {
  @include border-radius('top', $c-nutrition-summary__item-border-radius);
  @include padding('y', 2px);
  //font-size: em(12);
  font-size: 10px;
  font-weight: normal;

  @each $name, $hex in $c-nutrition-summary__item-colors {
    &--#{$name} {
      color: $hex;
    }
  }
}

.c-nutrition-summary__item--body {

  @include border-radius('bottom', $c-nutrition-summary__item-border-radius);
  color: $color-gray-3;
  //font-size: em(26);
  font-size: 20px;
  @include padding('y', 4px);
  min-width: 46px;

  @each $name, $hex in $c-nutrition-summary__item-colors {
    &--#{$name} {
      background: $hex;
    }
  }
}

.c-nutrition-summary__item__measure {
  display: block;
  //font-size: em(12);
  font-size: 10px;
  margin: -7px 0 0;
}

.c-nutrition-summary__divider {
  width: 2.5%;
}

.c-nutrition__padding-bottom-5px {
  padding-bottom: 5px;
}

.c-nutrition__padding-top-5px {
  padding-top: 5px;
}

.c-nutrition__margin-bottom-5px {
  margin-bottom: 5px;
}

.c-nutrition__margin-top-5px {
  margin-top: 5px;
}

.c-nutrition-facts {
  color: $c-nutrition-color;
  font-family: $c-nutrition-font;
  //font-size: em(12);
  font-size: 8pt;
}

.c-nutrition-facts__title {
  @include reset-type;
  //font-size: em(35);
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
}

.c-nutrition-facts__servings {
  line-height: 1.25;
}

.c-nutrition-facts__divder {
  width: 100%;
  height: 4px;
  background: $c-nutrition-color;
  margin: 6px 0 4px;
}

.c-nutrition-facts__divder--lg {
  height: 9px;
}

.c-nutrition-facts__22pt {
  font-size: 22pt;
}

.c-nutrition-facts__16pt {
  font-size: 16pt;
}

.c-nutrition-facts__10pt {
  font-size: 10pt;
}

.c-nutrition-facts__7pt {
  font-size: 7pt;
}


.c-nutrition-facts__table-header {
  @include reset-type;
  //font-size: em(9);
  font-size: 9px;
  font-weight: bold;
  padding: 2px 0;
}

.c-nutrition-facts__table-border-bottom {
  border-bottom: 1px $c-nutrition-color solid;
}

.c-nutrition-facts__table {
  width: 100%;

  + .c-nutrition-facts__divder {
    margin-top: -1px;
  }
}

.c-nutrition-facts__table__row {
  border-bottom: 1px $c-nutrition-color solid;
}

.c-nutrition-facts__table__item {
  padding: 1px 0;
  &:first-child { text-align: left; }
  &:last-child { text-align: right; }
  &:only-child { text-align: left; width: 100% }
}

.c-nutrition-facts__table__item--indent {
  padding-left: 16px;
}
.c-nutrition-facts__table__item--double-indent {
  padding-left: 32px;
}

