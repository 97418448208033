
// TODO: Move to mode-front-end
// Scrollable, but hide scrollbars
// https://stackoverflow.com/questions/20997183/how-to-hide-scrollbar-in-firefox
// https://css-tricks.com/snippets/css/hide-scrollbar-in-edge-ie-1011/
@mixin scroll-hidden {
  overflow: scroll;
  overflow: -moz-scrollbars-none;         // Firefox
  -webkit-overflow-scrolling: touch;      // iOS (smooth scroll)
  -ms-overflow-style: none;               // IE/Edge
  &::-webkit-scrollbar { display: none; } // Chrome/Safari
}

@mixin border-radius($side, $border-radius) {
  @if $side == 'top' or $side == 'bottom' {
    border-#{$side}-right-radius: $border-radius;
    border-#{$side}-left-radius: $border-radius;
  } @else if $side == 'right' or $side == 'left' {
    border-top-#{$side}-radius: $border-radius;
    border-bottom-#{$side}-radius: $border-radius;
  }
}

@mixin padding($dir: 'x', $px: 0) {
  @if $dir == 'x' {
    padding-right: $px;
    padding-left: $px;
  } @else if $dir == 'y' {
    padding-top: $px;
    padding-bottom: $px;
  }
}

@mixin reset-type {
  color: inherit;
  font-feature-settings: normal !important;
  font-variant-caps: normal;
  letter-spacing: 0;
  margin: 0;
  text-transform: none;
  -webkit-font-smoothing: subpixel-antialiased;
}
