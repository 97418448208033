.breadcrumbs {

  @include cta--sm();
  color: var(--breadcrumb-color);

  margin-top:15px;
  position:relative;
  z-index:9;

  display: none;

  @media print {
    display: none !important;
  }

  @include mq('md') {
    display: block;
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    li {
      white-space: nowrap;
      a {
        color: var(--breadcrumb-color);
        display: inline-block;
        text-decoration:underline;
      }

      &::after {
        content: ' / ';
        display: inline-block;
        padding: 0 7px;
      }

      &:last-child {
        a {
          text-decoration:none;
        }

        &::after {
          display: none;
        }
      }
    }
  }

}
