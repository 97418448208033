@include u-position('static', static);
@include u-position('relative', relative);
@include u-position('absolute', absolute);
@include u-position('fixed', fixed);

@include u-position('center', absolute) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@include u-position('bottom-center', absolute) {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

@include u-position('top-center', absolute) {
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

@include u-position('bottom-left', absolute) {
  bottom: 0;
  left: 0;
}

@include u-position('bottom-right', absolute) {
  bottom: 0;
  right: 0;
}

@include u-position('top-right', absolute) {
  top: 0;
  right: 0;
}

@include u-position('cover', absolute) {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@include u-position('right-center', absolute) {
  top: 50%;
  transform: translate(0, -50%);
  right: 0;
}

@include u-position('left-center', absolute, '1040') {
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
}

@include u-position('left-center', absolute, '1024') {
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
}
// absolute positions
.u-bottom-0 {
  bottom: 0;
}
.u-left-0 {
  left: 0;
}



// ------------------------------
// Responsive
// ------------------------------

// Max-width
// ...

// Min-width

@include u-position('bottom-center', absolute, 'lt-md') {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
@include u-position('cover', absolute, 'md') {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate(0, 0);
}

@include u-position('cover', absolute, 'sm') {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate(0, 0);
}

@include u-position('cover', absolute, '680') {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate(0, 0);
}
@include u-position('cover', absolute, 'lt-1024') {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate(0, 0);
}
@include u-position('cover', absolute, 'lt-680') {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate(0, 0);
}
@include u-position('cover', absolute, 'lt-lg') {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate(0, 0);
}
@include u-position('cover', absolute, 'lt-md') {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate(0, 0);
}
@include u-position('center', absolute, 'lg') {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@include u-position('center', absolute, '680') {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@include u-position('center', absolute, 'md') {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@include u-position('center', absolute, '1040') {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@include u-position('top-center', absolute, 'md') {
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

@include u-position('center', absolute, 'lt-1024') {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@include u-position('top-center', absolute, 'lt-1024') {
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

@include u-position('center', absolute, 'lt-1040') {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@include u-position('top-center', absolute, 'lt-1040') {
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}


