.c-link {
  color: $link-color;

  @include hover('is-active') {
    color: $link-color-hover;
  }

  // TODO: Move this icon adjustment to a separate modifier
  // .c-icon {
  //   margin: 0 em(6) 0 0;
  //   vertical-align: -5px;
  // }
}

// Telephone links
// TODO: Will this always be the case, or how will we make exceptions?
a[href^='tel:'] {
  color: inherit;
  text-decoration: none;

  @include hover {
    // opacity: 0.6;
    // color: $color-gray-2;
    color: $color-gray-3;
  }
}



// ------------------------------
// Hacks
// ------------------------------

// TODO: Might be worth making a separate font namespace if we treat fonts this way? `f-small-caps`, `f-button`, etc.
// Note: Uses `.u-small-caps`
._font-button {
  @include font-button;
}

// TODO: Better way to handle groups of links? Maybe a .c-link-set component?
// Note: Let’s leave the default underline for links.
._remove-underline-from-links a {
  border-bottom: 0;
  text-decoration: none;
}

._shift-right {
  @include clearfix;

  > * {
    float: right;
  }
}

// TODO: Clean up disable class (or pick better name; only used to make Search view's "Show:" look like a string instead of a link)
@mixin c-link--disabled {
  color: inherit;
  cursor: default;
  pointer-events: none;

  @include hover('is-active') {
    color: inherit;
  }
}
.c-link--disabled {
  @include c-link--disabled;
}
@include mq('620') {
  .c-link--disabled\@620 {
    @include c-link--disabled;
  }
}



// ------------------------------
// Colors
// ------------------------------

// TODO: Better naming than gold-dk?
.c-link--gold-dk {
  color: $color-gold-1;

  @include hover('is-active') {
    color: $color-brand-gold;
  }
}



// ------------------------------
// Themes
// ------------------------------

.c-link--box {
  display: inline-block;
  padding: 0.25em 0.8em;
  border: 1px solid currentColor;
  text-decoration: none;
}

@include mq('lt-620') {
  .c-link--box\@lt-620 {
    display: inline-block;
    padding: 0.25em 0.8em;
    border: 1px solid currentColor;
    text-decoration: none;
  }
}

// Note: Uses `.u-small-caps`
.c-link--cta {
  @include font-button;
  // TODO: How thick should bottom borders be?
  border-bottom: 1.5px solid currentColor; // 2px solid currentColor;
  text-decoration: none;
}

.c-link--share {
  display: inline-block;
  margin: 0 0 em(10);
  color: $color-gold-1;
  text-align: center;
  text-decoration: none;
}


// ------------------------------
// Sizes
// ------------------------------

.c-link--sm {
  font-size: 1em;
}



// ------------------------------
// Image Links
// ------------------------------

.c-link__figure {
  position: relative;
  overflow: hidden;

  // Gold tint
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 200ms ease;
    background: $color-gold-1;
    content: '';
    opacity: 0;
  }
}

.c-link__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition:
    transform 200ms ease-out,
    opacity 400ms ease-out;
  background: center / cover no-repeat;
  -webkit-backface-visibility: hidden;
}

// Hover State
.c-link {
  @include mq('lg') {
    @include hover {
      .c-link__img {
        transform: scale(1.05);
      }

      // Gold tint
      .c-link__figure::after {
        opacity: 0.2;
      }
    }
  }
}




.link {
  @include cta--lg();
  color: var(--link-color);
  text-decoration: none;
}

.link--dk {
  --link-color: #{$color-burnt-cacao};
}

.link--lt {
  --link-color: #{$color-brand-gold};
}

@mixin link($dark: false) {
  @extend .link;

  @if ($dark) {
    @extend .link--dk;
  } @else {
    @extend .link--lt;
  }
}
