@import '../../../../../../node_modules/mode-front-end/resources/assets/sass/common';
@import '../../base/common-redesign';

.announcement-modal {

  max-width: scale-1200(620px);

  .c-modal__close {
    top: 20px;
    right: 20px;
  }

  .c-modal__content {
    background: #000;
    padding: scale-1200(62px) scale-1200(30px) scale-1200(40px);
  }

  .announcement-modal__content {
    display: flex;
    flex-direction: column;
    gap: .8em;

    border: 1px solid $color-brand-red;

    padding: 30px;

    @include mq('md') {
      padding: scale-1200(60px) scale-1200(100px);
    }
  }

  h1 {
    font-variant-numeric: lining-nums proportional-nums;
    font-variant: normal;
    font-family: "Minion Pro";
    font-size: scale-1200(28px);
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: .1em;
    text-transform: uppercase;

    text-align: center;
  }

  .c-copy {
    text-align: center;
    @include body--md();
  }

  .c-hr {
    margin-bottom: 0;
  }

}
