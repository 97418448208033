// scss-lint:disable QualifyingElement

// textarea {
//   // HACK: Remove focus input (not best a11y practice, but...)
//   &.c-form__input:focus {
//     outline: none;
//   }
//   // Hide textarea scrollbar in IE until text is scrollable
//   &.c-form__input {
//     overflow: auto;
//   }
//   // Hide resize handle on textareas that automatically resize
//   // &.js-auto-size {
//   //   resize: none;
//   // }
// }

// // HACK: Add padding to bordered textareas
// textarea.u-border-gold {
//   padding: em(12) em(16) em(16);
// }

.c-form__input--textarea {
  padding: em(8) em(3);
}
