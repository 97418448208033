$desktop-bp: '870';

.feature-cards {
  padding-left: var(--page-margins-outer);
  padding-right: var(--page-margins-outer);

  display: flex;
  justify-content: center;
}

.feature-cards__content {
  display: grid;
  column-gap: 20px;
  row-gap: 40px;

  grid-template-columns: 1fr;

  @include mq($desktop-bp) {
    grid-template-columns: 1fr 1fr;
    max-width: scale-1200(1100px);
  }
}


.feature-cards__card {

  --feature-card-aspect-ratio: 326 / 404;
  @include mq($desktop-bp) {
    --feature-card-aspect-ratio: 540 / 670;
  }

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  &:hover{
    .feature-cards__card-background{
      img{
      transform:scale(1.05);
      transform-origin:center;
      transition: transform 1s ease-in-out;
      }

      &::after {
opacity:1;
transition: opacity 0.5s ease-in-out;
        background: linear-gradient(23deg, rgba(0, 0, 0, 0.5) 5%, rgba(0, 0, 0, 0.00) 100%);
      }
    }
  }
}

.feature-cards__card-background {
  grid-row: 1;
  grid-column: 1;
  aspect-ratio: var(--feature-card-aspect-ratio);
  will-change:transform;
  position: relative;
  overflow:hidden;
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    opacity:0;
    transition: opacity 0.5s ease-out;
    position: absolute;
    top: 0;
    left: 0;

    // background: linear-gradient(23deg, rgba(0, 0, 0, 0.14) 5%, rgba(0, 0, 0, 0.00) 100%);
  }

  img, video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease-in-out;
  }
}

.feature-cards__card-content {
  grid-row: 1;
  grid-column: 1;

  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  padding: 24px;
  @include mq($desktop-bp) {
    padding: scale-1200(34px);
  }
}

.feature-cards__subheading {
  @include heading--md();

  margin-bottom: 7px;
  @include mq($desktop-bp) {
    margin-bottom: scale-1200(8px);
  }
}

.feature-cards__heading {
  @include body--xl();

  margin-bottom: 8px;
  @include mq($desktop-bp) {
    margin-bottom: scale-1200(16px);
  }
}

.feature-cards__cta {
  @include full-area-cta();
}
