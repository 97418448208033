.c-button--pill {
  background-color: var(--btn-pill-background);
  transition: background-color 0.6s ease-in-out;
  overflow:hidden;
  position: static;

  @include cta--lg();
  letter-spacing: 2.15px;
  height: var(--pill-height);

  border: 1px solid var(--btn-pill-border);
  border-radius: 50px;

  color: var(--btn-pill-txt-color);
  text-decoration: none;
  line-height:normal; // this is a fix for Edge

  .inside-wrapper {


    white-space: nowrap;

    display: flex;
    align-items: center;
    column-gap: 17px; // spacing between text and icons

    padding-left: 15px;
    padding-right: 15px;


    .c-icon {
      color: var(--btn-pill-icon-color);
      transition: color 0.4s ease-in-out;
    }
  }

  .microsoft & {
    .btn--text {
      line-height: 0;
      padding-top: 0.17em;
    }
  }

  &.pill--icon-only {
    min-width: fluid(53px, 70px);
  }

  &.pill--arrow:hover{
    color: var(--btn-pill-txt-color);

    .c-icon{
      color: var(--btn-pill-txt-color);
      transition: color 0.6s ease-in-out;
      animation: arrow-move 0.8s ease-in-out forwards;
    }

    &.prev{
      color: var(--btn-pill-txt-color);

      .c-icon{
        color: var(--btn-pill-txt-color);
        transition: color 0.6s ease-in-out;
        animation: arrow-move 0.8s ease-in-out forwards;
      }
    }
  }

  @include mq('xl') {

    height: scale-1200(44px);
    .inside-wrapper{
      column-gap: scale-1200(15px);
      padding-left: scale-1200(20px);
      padding-right: scale-1200(20px);
    }
  }
}

.c-button--pill-dk {
  --btn-pill-border: #{$color-brand-gold};
  --btn-pill-txt-color: #{$color-burnt-cacao};
  --btn-pill-background: transparent;

  &:hover {
    --btn-pill-background: var(--btn-pill-border);
  }
}

.c-button--pill-lt {
  --btn-pill-border: #{$color-brand-gold};
  --btn-pill-txt-color: #{$color-bone};

  --btn-pill-background: transparent;

  &:hover {
    --btn-pill-background: var(--btn-pill-border);
  }
}

@mixin button--pill($dark: false) {
  @extend .c-button;
  @extend .c-button--pill;

  @if ($dark) {
    @extend .c-button--pill-dk;
  } @else {
    @extend .c-button--pill-lt;
  }

  .c-icon--plus {
    width: scale-vw-mobile(14px);
    height: scale-vw-mobile(14px);

    @include mq('xl') {
      width: scale-1200(19.5px);
      height: scale-1200(19.5px);
    }
  }

  .c-icon--social-pinterest, .c-icon--social-instagram  {
    width: scale-vw-mobile(14px);
    height: scale-vw-mobile(14px);

    @include mq('xl') {
      width: scale-1200(19.5px);
      height: scale-1200(19.5px);
    }
  }
}

@mixin button--pill-filter($dark: false) {
  --pill-height: 52px;
  @include button--pill($dark);

  height: 30px;

  @include mq('lg') {
    height: scale-1200(52px);
  }
}

@keyframes arrow-move{
  0%{
    opacity:1;
    transform:scale(1) translate3d(0, 0, 0);
    transform-origin:center;
  }

  25%{
    opacity:1;
    transform:scale(0) translate3d(-200px, 0, 0);
    transform-origin:center;
  }

  40%{
    opacity:1;
    transform:scale(0) translate3d(-200px, 0, 0);
    transform-origin:center;
  }

  75%{
    opacity:1;
    transform:scale(1) translate3d(0, 0, 0);
    transform-origin:center;
  }

  100%{
    opacity:1;
    transform:scale(1) translate3d(0, 0, 0);
    transform-origin:center;
  }
}

@keyframes arrow-move--opposite{
  0%{
    opacity:1;
    transform:scale(1) translate3d(0, 0, 0);
    transform-origin:center;
  }

  25%{
    opacity:1;
    transform:scale(0) translate3d(200px, 0, 0);
    transform-origin:center;
  }

  40%{
    opacity:1;
    transform:scale(0) translate3d(200px, 0, 0);
    transform-origin:center;
  }

  75%{
    opacity:1;
    transform:scale(1) translate3d(0, 0, 0);
    transform-origin:center;
  }

  100%{
    opacity:1;
    transform:scale(1) translate3d(0, 0, 0);
    transform-origin:center;
  }
}
