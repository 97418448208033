// Note: Uses `.u-small-caps`
.c-button--box {
  @include font-button;
  border: 1px solid currentColor;
  text-decoration: none;
  font-family: 'minion-pro';
}

.c-button--box-rounded{
  border-radius:20px;
}

// HACK: Vertically align small caps for all box variations.
// TODO: Getting mixed results with this. Look into.
[class*="c-button--box"] {
  padding: 0 0.8em;

  .supports-font-features.wf-active & > span:not(.c-icon) {
    padding: em(6, 18) 0 em(9, 18);
    line-height: 0.9;
  }
}

// HACK: Resize and position icons
[class*="c-button--box"] {
  // Arrows
  .c-icon--arrow,
  .c-icon--arrow-reverse {
    width: em(12, 18);
  }
  .c-icon--arrow {
    margin-left: 0.6em;
  }
  .c-icon--arrow-reverse {
    margin-right: 0.6em;
  }
  .u-display-inline-flex .c-icon--arrow {
    margin-top: em(3);
  }
  // Download
  .c-icon--download {
    margin-left: 0.8em;
  }
}



// ------------------------------
// Colors
// ------------------------------

// Note: Uses `.u-small-caps`
.c-button--box-fill {
  @include font-button;
  border: 0;
  background: $color-brand-gold;
  color: $color-black-1;
  text-decoration: none;

  @include hover {
    background: $color-gold-3;
    color: $color-black-1;
  }
}
.c-button--box-fill-red {
  @include font-button;
  border: 0;
  background: $color-brand-red;
  color: $color-white-1;
  text-decoration: none;

  @include hover {
    background: $color-red-2;
    color: $color-white-1;
  }
}



// ------------------------------
// Sizes
// ------------------------------

.c-button--box-md {
  padding: em(6, 18) em(14, 18);
}

.c-button--box-lg {
  padding: em(9, 18) em(16, 18);
}

.c-button--box-lg\@lg {
  @include mq('lg') {
    padding: em(9, 18) em(16, 18);
  }
}

.c-button--box-wide {
  min-width: 200px;
}

.c-button--box-full-width\@lt-sm {
  @include mq('lt-sm') {
    justify-content: space-between;
    width: 100%;
  }
}



// ------------------------------
// Border (Outer)
// ------------------------------

.c-button--box-border-red {
  position: relative;

  &::after {
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 2px solid $color-brand-red;
    content: '';
  }

  &:hover::after {
    border-color: $color-red-2;
  }
}



// ------------------------------
// Aloha Sunshine
// ------------------------------

.c-button--salmon {
  background-color: $color-salmon-1;
  position: relative;
  border-radius:5px;
  padding: 0.1em 0;
  font-size: 18px;
}

#pitcraft-modal{
  .bold-button--double-border {
    border: 1px solid $color-brand-gold;
    outline: 1px solid $color-brand-gold;
    outline-offset: 2px;
    padding: .2em 1em;
  }
}
