.product-card {
  --product-card-aspect-ratio: 275 / 350;

  position: relative;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content;
  row-gap: scale-1200(20px);

  @include body--md();
  text-decoration: none;
  color: var(--color-bone);

  &:focus {
    text-decoration: underline;
  }

  &:hover {
    .product-card__view {
      opacity: 1;
    }

    .product-card--hover-media {
      opacity: 1;
      transition-delay: 0s;
    }

    .product-card__media {
      &::after {
        opacity: 1;
      }
    }

  }
}

.product-card--has-hover {
  .product-card__media > img {
    opacity: 1;
    transition: opacity .2s ease-out;
    will-change: opacity;
  }

  &:hover {
    .product-card__media > img {
      opacity: 0;
    }

    .product-card--hover-media .product-card--hover-transition {
      transform: translate3d(-30%, 0, 0);
      transition-duration: 25s;
      transition-delay: 0s;
    }
  }

  .product-card--hover-media {
    grid-row: 1;
    grid-column: 1;

    position: relative;

    opacity: 0;
    transition: opacity 0s .2s;

    .product-card--hover-transition {
      width: 100%;
      height: 100%;
      transform: translate3d(30%, 0, 0);
      transition: transform 0s .2s ease-out;
    }

    img {
      transform: scale(2) translate3d(0, -10%, 0);
    }
  }
}

.product-card__media {

  position: relative;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;

  overflow: hidden;

  aspect-ratio: var(--product-card-aspect-ratio);

  img, video {

    grid-row: 1;
    grid-column: 1;

    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  > img {
    transform: scale(1.01);
  }

  img {
    position: relative;
    z-index: 1;
  }

  &::after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);
    z-index: 3;
    opacity: 0;
    transition: opacity .2s ease-out;
    will-change: opacity;
    border-bottom: 8px solid $color-brand-red;
  }
}

.product-card__flags {
  position: absolute;

  z-index: 3;

  width: 100%;

  display: flex;
  flex-wrap: nowrap;
  column-gap: 6px;
  justify-content: flex-end;
  top: 13px;
  right: 9px;

  @include mq('680') {
    top: scale-1200(16px);
    right: scale-1200(16px);
    column-gap: 10px;
  }
}

.product-card__flag {
  flex-shrink: 0;
}

.product-card__flag--featured {
  max-width: 91px;

  @include mq('680') {
    max-width: scale-1200(123.3px);
  }
}

.product-card__flag--new {
  max-width: 48px;
  @include mq('680') {
    max-width: scale-1200(69.87px);
  }
}

.product-card__caption {
  p {
    padding-right: 15px;
  }
}

.product-card__view {
  display: flex;
  justify-content: center;
  align-items: center;

  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 4;

  opacity: 0;
  // transition: opacity .2s ease-out;

  width: scale-1200(57.5px);
  height: scale-1200(57.5px);

  text-decoration: none;

  &::after {
    content: '';

    width: 100%;
    height: 100%;

    border: 1px solid var(--color-bone);

    transform: rotate(-45deg);
    transition: transform .2s var(--motion-easing);

    position: absolute;
    top: 0;
    left: 0;
  }

  span {
    @include cta--xs();
    color:  var(--color-bone);
  }
}

.product-card__lto {
  @include cta--md();
  color: $color-brand-gold;
  position: relative;
  top: -.15em;
}
