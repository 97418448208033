.full-cta {
  display: grid;

  &.hover {
    .full-cta__background {
      img {
        opacity: .2;
      }
    }
  }
}

.full-cta__background {
  grid-column: 1;
  grid-row: 1;

  background: url('/img/texture/Grain-tile@1x.jpg');

  position: relative;

  min-height: 165.0666667vw;
  @include mq('680') {
    min-height: 60.952381vw;
  }

  img {
    @include object-fit--cover();
    transition: opacity 1s ease-out;
    opacity: .3;
  }
}

.full-cta__content {
  grid-column: 1;
  grid-row: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  .full-cta__subheading {
    @include heading--sm();
    color: var(--full-cta-subheading);

    margin-bottom: scale-vw-mobile(20px);

    @include mq('xl') {
      margin-bottom: scale-1200(35px);
    }
  }

  .full-cta__heading {
    @include heading--lg();
    color: var(--full-cta-heading);
    text-align: center;

    margin-bottom: scale-vw-mobile(45px);
    max-width: 80vw;

    @include mq('680') {
      max-width: 59vw;
    }

    @include mq('xl') {
      max-width: scale-1200(662px);
      margin-bottom: scale-1200(85px);
    }
  }

  @keyframes fullCtaDiamond {
    from {
      stroke-dashoffset: 193.989990234375px;
    }

    to {
      stroke-dashoffset: 387.97998046875px;
    }
  }

  .full-cta__cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    row-gap: 30px;

    text-decoration: none;

    > .c-icon {
      width: 70px;
      height: 70px;
      max-width: 70px;
      max-height: 70px;
    }

    > span:last-child {
      color: var(--full-cta-copy);
      @include cta--lg();
    }

    // Animate the border for desktop
    @include mq('xl') {
      svg rect {
        stroke-dashoffset: 387.97998046875px;
        stroke-dasharray: 193.989990234375px;
      }

      &:hover {
        svg rect {
          animation-duration: 1s;
          animation-name: fullCtaDiamond;
          animation-fill-mode: forwards;
          animation-timing-function: var(--motion-easing);
        }
      }
    }
  }
}
