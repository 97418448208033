@use "sass:math";

.dropdown__group {
  position: relative;
}

.dropdown {

  --dropdown-content-height: 300px;
  --dropdown-min-width: 200px;
  --dropdown-width: 200px;
  --dropdown-max-width: 600px;
  --dropdown-hover: #{$color-black--50-opacity};
  --dropdown-outline: #{$color-bone--25-opacity};
  --dropdown-selected-background: var(--color-brand-red);
  --dropdown-option-vertical-padding: #{scale-1200(8px)};
  --dropdown-option-horizontal-padding: #{scale-1200(16px)};
  --dropdown-btn-padding: #{scale-1200(16px)};
  --dropdown-button-text-color: var(--color-bone);
  --dropdown-button-outline: var(--color-bone);
  --dropdown-content-background-height: calc(var(--dropdown-content-height) + 48px + 10px);
  --dropdown-height: 42px;

  display: inline-flex;
  position: relative;
  z-index: 5;
  width: var(--dropdown-width);
  min-width: var(--dropdown-min-width);
  max-width: var(--dropdown-max-width);

  &::before {
    content: '';
    z-index: 1;
    position: absolute;
    height: var(--dropdown-content-background-height);
    width: 100%;
    background: url('#{cache-ts-url('/img/pinnacle/backgrounds/dd-paper-bg.webp')}');
    background-size: cover;
    background-repeat: no-repeat;
    clip-path: inset(0 0 var(--dropdown-content-height) 0);
    transition: clip-path .5s var(--motion-easing), opacity 0s .5s;
    opacity: 0;
    outline: 1px solid var(--dropdown-outline);
    pointer-events: none;
  }
}

.dropdown__option-length {
  top: 0;
  left: 0;
  clip-path: rect(100% 100% 100% 100%);
  visibility: hidden;
  position: absolute;
  width: min-content;
  white-space: nowrap;
}

.dropdown__entry-point {
  position: relative;
  width: 100%;
  z-index: 1;
}

.dropdown__btn {
  position: relative;

  cursor: pointer;

  display: block;
  width: 100%;

  &:hover, &:focus {
    outline: none;
  }

  text-decoration: none;

  .dropdown__btn-content {
    padding: 0 scale-1200(16px);
  }

  .dropdown__btn-copy {
    @include cta--sm();
    text-align: left;
    line-height: 1;
    position: relative;
    transition: transform .2s ease-out;
    white-space: nowrap;
    display: block;
    transform: translate3d(0, 0, 0);
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  input.dropdown__btn-copy {

    &::placeholder {
      transition: color .2s ease-out;
    }

    &:focus {
      &::placeholder {
        color: rgba($color-bone, .4);
      }
    }
  }

  .dropdown__btn-content {
    &::before {
      content: '';
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      transform: translate3d(-5px, -5px, 0);
      background: var(--dropdown-selected-background);

      position: absolute;
      top: 0;
      left: 0;

      transition: clip-path .3s .2s var(--motion-easing);
      clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    }
  }
}

/* For browsers that support `scrollbar-*` properties */
@supports (scrollbar-color: auto) {
  .dropdown__options {
    scrollbar-color: $color-black $color-black--75-flat;
    scrollbar-width: thin;
  }
}

/* Otherwise, use `::-webkit-scrollbar-*` pseudo-elements */
@supports selector(::-webkit-scrollbar) {
  .dropdown__options::-webkit-scrollbar {
    background: $color-black--75-flat;
    width: 10px;
  }
  .dropdown__options::-webkit-scrollbar-thumb {
    background: $color-black;
  }
}

input[data-dropdown-selected-label] {
  background: transparent;
  width: 100%;
  height: 12px;

  &::placeholder {
    @include cta--sm();
    color: var(--dropdown-button-text-color);
  }
}

.dropdown__btn-icon {

  &.dropdown-arrow {
    width: 12px;
    height: 12px;
    display: block;
    transform-origin: center center;
    transition: transform .2s var(--motion-easing);

    .c-icon {
      width: 12px;
      height: 12px;
      display: block;
    }
  }

  &.dropdown-search {
    width: 10px;
    height: 10px;
    display: block;
    transform-origin: center center;
    // transition: transform .2s var(--motion-easing);

    .c-icon {
      width: 10px;
      height: 10px;
      display: block;
    }
  }

  &.close {
    width: 12px;
    height: 12px;
    .c-icon {
      width: 12px;
      height: 12px;
      display: block;
    }
  }
}

.dropdown__btn-content {
  display: grid;
  grid-template-columns: 1fr 12px;
  align-items: center;
  column-gap: 10px;

  position: relative;
  overflow: hidden;

  height: var(--dropdown-height);

  color: var(--btn-copy);

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid var(--dropdown-button-outline);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

.dropdown__btn-icon {
  position: relative;
  grid-column: 2;
  grid-row: 1;
}

.has-selection {

  .dropdown__deselect {
    display: flex;
  }

  .dropdown__btn-icon.dropdown-arrow,
  .dropdown__btn-icon.dropdown-search {
    display: none;
  }
}

.dropdown__deselect {
  position: absolute;
  right: var(--dropdown-btn-padding);
  top: 50%;
  transform: translate3d(0, -50%, 0);

  display: none;
  justify-content: right;
  align-items: center;
  z-index: 3;

  height: 32px;
  width: 32px;
}

.dropdown__content {
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 0;
  height: var(--dropdown-content-height);
  transition: max-height .5s var(--motion-easing);
  overflow: hidden;
  z-index: 2;
}

.dropdown__options {
  height: 100%;
  overflow-y: auto;
}

.dropdown__option {
  display: block;
}

.dropdown__option-reference {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}

.dropdown__option-btn {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: var(--dropdown-option-vertical-padding) var(--dropdown-option-horizontal-padding);

  &:hover, &:focus, &.focus {
    background: var(--dropdown-hover);
    outline: none;
  }
}

.dropdown__option--no-results {
  .dropdown__option-btn {
    &:hover, &:focus, &.focus {
      background: transparent;
      outline: none;
      pointer-events: none;
    }
  }
}

.dropdown__option-text {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include copy-dropdown-option();
}

.dropdown.open {

  &::before {
    opacity: 1;
    transition-delay: 0s, 0s;
    clip-path: inset(0 0 0 0);
  }

  .dropdown-arrow {
    transform: rotate(180deg);
  }

  .dropdown__content {
    max-height: calc(var(--dropdown-content-height) + 1px);
  }

  .dropdown__btn-content {
    &::after {
      opacity: 0;
    }
  }
}

.dropdown.has-selection {
  .dropdown__btn-content {
    &::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
  }
}






// ------------------------------
// Mixins
// ------------------------------

@mixin c-dropdown__toggle {
  .c-icon--arrow-down {
    margin-left: 0.4em;
  }
  .c-icon--info {
    transform: rotate(90deg);
    stroke:none;
  }
  &.is-active {
    .c-icon--arrow-down {
      transform: rotate(180deg);
    }
    .c-icon--info {
      transform: rotate(0deg);
      stroke:none;
    }
  }
}


@mixin c-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  // TODO: Make a mixin for these quick visibility includes
  transition: opacity 300ms ease,
    visibility 300ms linear 0s;
  background: $color-brown-2;
  opacity: 0;
  visibility: hidden;
  pointer-events: none; // Make sure menu isn't an invisible click area
  z-index: 80;

  // State
  &.is-active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

@mixin c-dropdown__item {
  &:not(:first-child) {
    border-top: 1px solid $color-brown-3;
  }
}

@mixin c-dropdown__link {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: em(40);
  padding: 0.5em 1em;
  color: $color-gray-2;
  line-height: math.div(20, 16);
  text-decoration: none;

  @include hover {
    background: $color-brown-3;
    color: $color-gray-2;
  }

  // TODO: Set default `mark` highlight style (yellow comes from Normalize)
  mark {
    background: none;
    color: $color-gray-3;
  }
}



// ------------------------------
// Classes
// ------------------------------

.c-dropdown__toggle {
  @include c-dropdown__toggle;
}
.c-dropdown {
  @include c-dropdown;
}
.c-dropdown__item {
  @include c-dropdown__item;
}
.c-dropdown__link {
  @include c-dropdown__link;
}

// Responsive
@include mq('lt-620') {
  .c-dropdown__toggle\@lt-620 {
    @include c-dropdown__toggle;
  }
  .c-dropdown\@lt-620 {
    @include c-dropdown;
  }
  .c-dropdown__item\@lt-620 {
    @include c-dropdown__item;
  }
  .c-dropdown__link\@lt-620 {
    @include c-dropdown__link;
  }
}
