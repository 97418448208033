.footnotes {
  display: flex;
  justify-content: center;

  padding-left: var(--page-margins-outer);
  padding-right: var(--page-margins-outer);

  padding-top: 30px;
  padding-bottom: 43px;

  @include mq('xl') {
    padding-top: scale-1200(64px);
    padding-bottom: scale-1200(80px);
  }
}

.footnotes__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  row-gap: scale-1200(20px);

  div {
    color: $color-brand-legal;
    @include body--sm();
    text-align: center;
    max-width: 1010px;

    br {
      display: none;
    }
  }
}
