.btn {
  --btn-background: var(--color-brand-red);
  --btn-copy: var(--color-bone);
  --btn-hover-copy: var(--color-bone);

  cursor: pointer;

  display: block;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: var(--btn-hover-copy);
    }
  }
}

.btn__content {
  display: flex;
  align-items: center;
  column-gap: 10px;

  position: relative;
  overflow: hidden;

  background: var(--btn-background);
  color: var(--btn-copy);
}

.btn__icon, .btn__copy {
  position: relative;
}

.btn--primary {
  --btn-background: var(--color-brand-red);
  --btn-copy: var(--color-bone);

  text-decoration: none;

  .btn__content {
    padding: scale-1200(16px);
  }

  .btn__copy {
    @include cta--sm();
    line-height: 1;
    transition: transform .2s ease-out;
    flex-basis: 100%;
  }

  .btn__icon {

    &.btn-arrow {
      width: 12px;
      height: 10px;

      .c-icon {
        width: 12px;
        height: 10px;
        display: block;
      }
    }

    &.btn-plus {
      width: 10px;
      height: 10px;

      .c-icon {
        width: 10px;
        height: 10px;
        display: block;
      }
    }

    &:first-child {
      transform: translate3d(-100%, 0, 0);
      opacity: 0;
      transition: transform .2s ease-out, opacity .2s ease-out;
      position: absolute;
    }

    &:last-child {
      transform: translate3d(0, 0, 0);
      transition: transform .2s ease-out, opacity .2s ease-out;
    }
  }

  .btn__content {
    &::before {
      content: '';
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      transform: translate3d(-5px, -5px, 0);
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), url(cache-ts-url('/img/pinnacle/global/btn-gold-texture.jpg'));
      background-size: cover;
      background-repeat: no-repeat;
      background-position: right top;

      position: absolute;
      top: 0;
      left: 0;

      clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
      transition: clip-path .3s var(--motion-easing);
    }
  }

  &:focus-visible, &.active {
    .btn__icon:first-child {
      // transition-delay: .5s;
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    .btn__icon:last-child {
      transform: translate3d(100%, 0, 0);
      opacity: 0;
    }

    .btn__copy {
      // transition-delay: .5s;
      transform: translate3d(24px, 0, 0);
    }

    .btn__content {
      &::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      }
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .btn__icon:first-child {
        // transition-delay: .5s;
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }

      .btn__icon:last-child {
        transform: translate3d(100%, 0, 0);
        opacity: 0;
      }

      .btn__copy {
        // transition-delay: .5s;
        transform: translate3d(24px, 0, 0);
      }

      .btn__content {
        &::before {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        }
      }
    }
  }
}

.btn--primary-line {
  --btn-background: transparent;
  --btn-copy: var(--color-bone);

  .btn__content {

    &::before {
      content: '';
      background: var(--color-brand-red);
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      border: 1px solid var(--color-bone);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
}

.btn--centered {
  .btn__copy {
    text-align: center;
    width: 100%;
  }
}

.btn--no-icon {
  .btn__copy {
    transition: none !important;
    transform: none !important;
  }
}

.btn--secondary {
  --btn-background: transparent;
  --btn-copy: var(--color-bone);

  align-self: flex-start;
  display: inline-flex;

  text-decoration: none;

  .btn__content {

    overflow: visible;

    &::before {
      content: '';

      width: 100%;
      height: 1px;

      position: absolute;
      bottom: -10px;
      left: 0;

      background: var(--color-bone);

      transition: clip-path .3s .2s var(--motion-easing);
      clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    }

    &::after {
      display: none;
    }

  }

  .btn__copy {
    @include cta--md();
    line-height: 1;
    transform: translate3d(0, 0, 0);
    transition: transform .2s ease-out;
    white-space: nowrap;
  }

  .btn__icon {

    width: 12px;
    height: 10px;

    @include mq('680') {
      width: scale-1200(12px);
      height: scale-1200(10px);
    }

    &:first-child {
      transform: translate3d(-100%, 0, 0);
      opacity: 0;
      transition: transform .2s ease-out, opacity .2s ease-out;
      position: absolute;
    }

    &:last-child {
      transform: translate3d(0, 0, 0);
      transition: transform .2s ease-out, opacity .2s ease-out;
    }

    .c-icon {

      width: 12px;
      height: 10px;
      display: block;

      @include mq('680') {
        width: scale-1200(12px);
        height: scale-1200(10px);
      }
    }
  }

  &:focus-visible, &.active {

    .btn__icon:first-child {
      // transition-delay: .5s;
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    .btn__icon:last-child {
      transform: translate3d(100%, 0, 0);
      opacity: 0;
    }

    .btn__copy {
      // transition-delay: .5s;
      transform: translate3d(24px, 0, 0);
    }

    .btn__content {
      &::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      }
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .btn__icon:first-child {
        // transition-delay: .5s;
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }

      .btn__icon:last-child {
        transform: translate3d(100%, 0, 0);
        opacity: 0;
      }

      .btn__copy {
        // transition-delay: .5s;
        transform: translate3d(24px, 0, 0);
      }

      .btn__content {
        &::before {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        }
      }
    }
  }
}

.btn--link {
  --btn-background: transparent;

  .btn__copy {
    text-align: center;
    @include cta--xs();
  }
}

@import './index-legacy';
