.purveyors-modal {
  max-width: 549px;
  background-color: #fff;

  .c-modal__content {
    background: transparent;
    padding: 0;
  }

  .c-modal__close {
    top: 15px;
    right: 15px;
    left: auto;
  }
}


.purveyors-modal__content {

  padding-left: 18px;
  padding-right: 18px;
  padding-top: scale-1200(39px);
  padding-bottom: 15px;

  @include mq('md') {
    padding-left: scale-1200(56px);
    padding-right: scale-1200(56px);
    padding-top: scale-1200(39px);
    padding-bottom: 15px;
  }

  display: flex;
  flex-direction: column;
  gap: 1.5em;

  h1, h2 {
    @include heading--md();
    text-align: center;
    color: black;
    line-height: 1.0909090909 !important;
    font-weight: 400;
    font-variant: normal;
    text-transform: uppercase;
  }

  p {
    @include body--md();
    text-align: center;
    color: black;
    line-height: 1.25 !important;
  }
}

.purveyors-modal__hero {
  border: 1px solid var(--color-brand-gold);
}

.purveyors-modal__heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625em;

  position: relative;

  max-width: 88%;
  margin-top: -30%;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  border: 1px solid var(--color-brand-gold);
  padding-top: scale-1200(35px);
  padding-left: scale-1200(28px);
  padding-right: scale-1200(28px);
  padding-bottom: scale-1200(20px);

  .c-icon--filigree {
    max-width: 75px;
  }
}

.purveyors-modal__medallion {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate3d(-50%, -50%, 0);
  width: 65px;
  height: 44px;
}

.purveyors-modal__copy {
  display: flex;
  flex-direction: column;
  gap: 0.625em;
}

.purveyors-modal__cta {
  display: flex;
  justify-content: center;
}

.btn.btn--purveyor-special {

  --btn-background: var(--color-brand-red);

  position: relative;

  .btn__copy {
    @include heading--sm();
    line-height: 1;
  }

  .btn__icon.btn-arrow {
    margin-top: .05em;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    border: 1px solid var(--color-brand-gold);
    transform: translate3d(-3px, -3px, 0);
  }

}

.purveyors-modal__footer {
  text-align: center;
  @include body--sm();
  color: #000;
}
