.section-half-half {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;

  position: relative;
  text-align: left;
  grid-row-gap: 31px;

  @include mq('lg') {
    grid-row-gap: initial;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: scale-1200(100px);
  }

.section__copy{
  h3{
    @include heading--md();
  }
}

  &.half-half__left {
    @include mq('lg') {
      @include page-padding--left-outer();

      .half-half__content {
        grid-column: 1;
        grid-row: 1;
      }

      .half-half__image {
        grid-column: 2;
        grid-row: 1;


      }
    }
    @include mq('lt-lg') {
      .half-half__image {
        grid-column: 1;
        grid-row: 2;
      }
    }

    &.sticky--image{
      .half-half__image{
        display: grid;
        grid-template-columns: 1fr;

        grid-row-gap: scale-1200(39px);
        text-align: center;
        max-width: 100%;
        height: 100%;

        @include mq('lg'){
          grid-template-rows: auto;
          align-items:start;
          grid-column:2;
        }
        .half-half__background{
          @include mq('lg') {
            position: sticky;
            top:  var(--header-full-height);
            align-self: start;
            display:grid;
            grid-template-rows:auto;
            height:auto;
            }
        }
      }
    }

  }

  .half-half__image {
    flex: 1;
    position: relative;

    img {
      width: 100%;
      height: 100%;

    }
  }

  .half-half__content {
    text-align: left;
    flex: 1;

    @include mq('lt-lg') {
      text-align: center;
      @include main-container();
    }

    ul{
      color:$color-bone;
      @include sans-serif--lg();
    }
  }

  ._nutrition-icons {
    padding-top: scale-1200(74px);
  }
  .section__subheading {
    @include heading--sm();
    color: $color-brand-gold;
    margin-bottom: scale-1200(30px);

    @include mq('lg') {
      text-align: left;

    }
  }

  .section__heading {
    @include heading--lg();
    color: $color-bone;
    margin-bottom: scale-1200(30px);

    @include mq('lg') {
      text-align: left;

    }
  }

  .half-half__content {

    @include body--md();

    color: var(--section-header-copy);
    @include mq('lt-lg'){
      grid-column:1;
    }
    @include mq('lg') {
      .section__copy {
        @include body--md();

        max-width: 570px;
      }
      max-width: 34vw;
    }

    &-box-wrapper {
      display: grid;
      grid-template-columns: 1fr;

      grid-row-gap: scale-1200(39px);
      text-align: center;
      max-width: 100%;
      height: 100%;
      grid-column:1;
      @include mq('lg'){
        grid-column:2;
        grid-template-rows: 1fr 1fr;
      }

      &.sticky{
        @include mq('lg'){
          grid-template-rows: auto;
          align-items:start;
        }
      }
      .half-half__content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $color-burnt-cacao;
        text-align: center;
        min-height:300px;

        .section__heading {
          @include heading--lg();
          color: $color-bone;
          text-align: center;
          margin-bottom: scale-1200(20px);
        }

        .section__copy {
          @include body--sm();
          font-style: italic;
          max-width: 570px;
          padding:0 20px;
        }

        .link-wrap {
          max-width: 77%;
        }

        a {
          @include cta--lg();
          color: $color-bone;
        }
      }


    }
  }

  .sticky{
    @include mq('lg') {
    position: sticky;
    top:  var(--header-full-height);
    align-self: start;
    display:grid;
    grid-template-rows:auto;
    height:auto;
    }
  }

  &.half-half-content-boxes {
    padding: scale-vw-mobile(60px) 0 scale-vw-mobile(60px);
    @include mq('lg') {
    padding: scale-1200(109px) 0 scale-1200(120px);
    }
    align-items: flex-start;

    &.half-half-content-grid{
      .half-half__content-box-wrapper:not(._aha-table){
        grid-template-rows: none;


        .half-half__content-box{
          margin:scale-1200(30px) 0 0 0;
          padding:scale-1200(30px) scale-vw-mobile(20px);
          @include mq('lg') {
            padding:scale-1200(30px) 0;
          }
        }
      }
    }
  }

  .section__disclaimer {
    @include copy--fine-print();
    color: var(--section-header-disclaimer);
    padding-top: scale-1200(17px);
    @include mq('lt-lg'){
      padding-bottom: scale-1200(40px);
    }
    color: var(--section-header-copy);
  }

  &.padding-vertical{
    border-bottom:0;
    margin:scale-1200(50px) 0;
    @include mq('lg') {
      @include main-container();
    }
  }

  &.section--not-full-width{
    margin:0 scale-1200(136px);
    border-bottom:0px;

    @include mq('lt-lg') {
      text-align: center;
      margin:6.3vw 0;
      @include main-container();
    }
  }
}
