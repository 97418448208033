.section-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.section-header__content {
  z-index:2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include main-container();

  margin: 6.3vw auto 0;

  width: 100%;

  @include mq('680') {
    width: 64vw;
    margin: 0 auto;
  }

  @include mq('lg') {
    width: 64vw;
  }

  @include mq('xl') {
    padding-left: 0;
    padding-right: 0;
    width: scale-1200(750px);
  }

  // Try and remove an excess spacing from the last element
  > *:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .section-header__ctas {
    @include mq('lt-680') {
      margin: 0 auto 6.3vw;
    }
  }

  .section-header__disclaimer {
    text-align: center;
    @include body--sm();
    color:$color-brand-legal;
  }
}

.section-header__subheading {
  @include heading--md();
  text-align: center;
  color: var(--section-header-subheading);
  line-height: 1;

  margin-bottom: scale-vw-mobile(15px);
  @include mq('xl') {
    margin-bottom: scale-1200(25px);
  }
}

.section-header__heading {
  color: var(--section-header-heading);
  text-align: center;
  @include heading--lg();
  margin-bottom: scale-1200(20px);
}

.section-header__copy {
  text-align: center;
  margin-bottom: scale-vw-mobile(68px);
  @include body--lg();

  @include mq('lg') {
    @include body--lg();
    margin-bottom: scale-1200(35px);
    max-width: scale-1200(720px);
  }

  color: var(--section-header-copy);
}

.section-header__disclaimer {
  // @include copy--fine-print();
  @include body--sm();
  color: $color-brand-legal;
  padding-bottom: scale-1200(40px);
}
