.c-brochure {
  display: block;
  width: 100%;
  height: 550px;
  max-height: 70vh;
  // width: percentage(math.div(3,4));
  margin: 0 auto;

  // @include mq('md') {
  //   width: 100%;
  // }

  @include mq('lt-md') {
    width: 90%;
  }
}
