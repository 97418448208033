.next-post--container {

  height: 100%;
  display: grid;
  width:100%;
  grid-template-columns: 1fr;
  flex-shrink: 0;

  margin-top: scale-vw-mobile(76px);

  @media (min-width: 680px) and (min-height: 870px) {
    width: scale-1200(523px);
    margin-top: 0;
    margin-left: scale-1200(194px);
  }

  .next-post {
    height: 100%;
    display: grid;
    grid-template-areas:
      "image"
      "cta";

    width: 100%;
    background-color: $color-black;

    @media (min-width: 680px) and (max-height: 870px) {
      grid-template-areas: "image cta";
    }
  }

  .next-post--sub {
    position: absolute;
    z-index: 6;
    color: $color-bone;
    @include cta--lg();
    top: $page-margins--mobile;
    left: $page-margins--mobile;

    @media (min-width: 680px) and (min-height: 870px) {
      top: calc(var(--header-full-height) + scale-1200(20px));
      left: scale-1200(51px);
      grid-template-areas: "image cta";
    }
  }

  .gold-image {

    pointer-events: none;
    grid-area: image;

    @media (min-width: 680px) and (min-height: 870px) {
      width: 100%;
      min-height: scale-1200(680px);
    }

    @media (min-width: 680px) and (max-height: 870px) {
      width: 30vw;
    }

    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $color-brand-gold;
      opacity: 1;
      display:block;
      z-index:4;
    }

    img {
      z-index:5;
      opacity: 0.43;
    }
  }

  .title-wrapper {

    grid-area: cta;

    background-color: $color-black;
    color: $color-bone;

    min-height: scale-vw-mobile(193px);

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 41px $page-margins--mobile 26px $page-margins--mobile;

    @media (min-width: 680px) and (min-height: 870px) {
      padding-left: scale-1200(51px);
      padding-bottom: scale-1200(56px);
    }

    @include mq('680') {

    }

    h4 {
      @include heading--lg();
      padding-bottom: 25px;
    }

    a {
      // margin: 0 105px 56px 55px;
      width: fluid(53px, 70px);
      @include button--pill();
      @include full-area-cta();
    }
  }
}
