@mixin body--show-backdrop() {
  &::before {
    opacity: .8;
    pointer-events: auto;
  }
}

@mixin ticker-tape($opacity: 1) {
  &::after {
    content: '';
    position: absolute;
    background-image: var(--ticker-tape-image-url);
    background-repeat: repeat-x;
    position: absolute;
    bottom: 30px;
    left: 0;
    opacity: #{$opacity};
    transition: opacity .5s .75s var(--motion-easing);

    width: 100%;
    height: 55px;
    background-size: contain;
    background-position: center;

    display: none;

    @include mq('sm') {
      background-repeat: no-repeat;
    }

    @include mq('lg') {
      height: scale-1200(55px);
    }

    @media (min-width: 1920px) {
      height: clamp(50px, 3.4vw, 100px);
    }

    @media (min-width: 3800px) {
      height: clamp(100px, 5.2vw, 200px);
    }

    @media (min-width: 7200px) {
      display: none;
    }

    // @include mq('xl') {
    //   background-size: contain;
    //   bottom: scale-1200(20px);
    // }
  }
}
