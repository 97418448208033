@use "sass:math";

// scss-lint:disable DeclarationOrder


[role="tab"] {

}

[role="tabpanel"] {
  &:not(.selected) {
    display: none;
  }
}




// .c-tabs {
// }

.c-tabs__nav {
  display: flex;
  flex-wrap: none;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 1em;
}

.c-tabs__nav-item {
  flex: 1 1 auto;
  line-height: math.div(15, 13);
  text-align: center;

  &:not(:first-child) {
    padding-left: 1em;
  }

  // HACK: Force tab nav items to be even, quarter-width when text wraps
  @media (max-width: em(560, 16)) {
    flex: 1 1 25%;

    &:not(:first-child) {
      padding-left: 0.1em;
    }
  }

  // Shrink fonts so they fit on mobile
  @include mq('lt-sm') {
    font-size: 0.75em;
  }
}

.c-tabs__nav-link {
  opacity: 0.6;

  &[aria-selected="true"] {
    color: $link-color; // Keep default link color
    opacity: 1;
  }
}

.c-tabs__content {
  position: relative;
}

.c-tabs__pane {
  height: 0;
  transition: none;
  opacity: 0;
  visibility: hidden;

  &.selected {
    height: auto;
    opacity: 1;
    visibility: visible;
  }
}
