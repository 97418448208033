$font-size: 16; // in px

$font-family--serif: minion-pro, 'times new roman', times, georgia, serif;
$font-family--serif-display: minion-pro-display, serif;

$font-family--sans-serif: myriad-pro-semiextended, sans-serif;

$font-weight: 400;
$font-weight--medium: 500;
$font-weight--semibold: 600;
$font-weight--bold: 700;
