// Serif

@mixin font-serif($style: normal) {
  font-family: $font-family--serif;
  font-weight: 400;
  font-style: $style;
}

@mixin font-serif--medium($style: normal) {
  font-family: $font-family--serif;
  font-weight: 500;
  font-style: $style;
}

@mixin font-serif--bold($style: normal) {
  font-family: $font-family--serif;
  font-weight: 700;
  font-style: $style;
}

// Serif display

@mixin font-serif--display($style: normal) {
  font-family: $font-family--serif-display;
  font-weight: 400;
  font-style: $style;
  -webkit-font-smoothing: antialiased;
}

@mixin font-serif--display-medium($style: normal) {
  font-family: $font-family--serif-display;
  font-weight: 500;
  font-style: $style;
  -webkit-font-smoothing: antialiased;
}

@mixin font-serif--display-semibold($style: normal) {
  font-family: $font-family--serif-display;
  font-weight: 600;
  font-style: $style;
  -webkit-font-smoothing: antialiased;
}

@mixin font-serif--display-bold($style: normal) {
  font-family: $font-family--serif;
  font-weight: 700;
  font-style: $style;
  -webkit-font-smoothing: antialiased;
}

// // Caption

// @mixin font-serif--caption($style: normal) {
//   font-family: $font-family--serif-caption;
//   font-weight: 400;
//   font-style: $style;
// }

// @mixin font-serif--caption-medium($style: normal) {
//   font-family: $font-family--serif-caption;
//   font-weight: 500;
//   font-style: $style;
// }

// @mixin font-serif--caption-semibold($style: normal) {
//   font-family: $font-family--serif-caption;
//   font-weight: 600;
//   font-style: $style;
// }

// @mixin font-serif--caption-bold($style: normal) {
//   font-family: $font-family--serif-caption;
//   font-weight: 700;
//   font-style: $style;
// }

// Sans serif

@mixin font-sans-serif($style: normal) {
  font-family: $font-family--sans-serif;
  font-weight: 300;
  font-style: $style;
}

@mixin font-sans-serif--medium($style: normal) {
  font-family: $font-family--sans-serif;
  font-weight: 400;
  font-style: $style;
}

@mixin font-sans-serif--semibold($style: normal) {
  font-family: $font-family--sans-serif;
  // font-weight: 500;
  font-style: $style;
}

@mixin font-sans-serif--bold($style: normal) {
  font-family: $font-family--sans-serif;
  font-weight: 600;
  font-style: $style;
}

@mixin heading--xxl() {
  @include font-serif--display();
  text-transform: uppercase;
  letter-spacing: #{letter-spacing(3.6px, 36px)};
  font-variant-numeric: lining-nums proportional-nums;

  font-size: 36px;
  line-height: 1.15;

  @include mq('md') {
    font-size: 44px;
  }

  @include mq('xl') {
    font-size: scale-1200(54px);
  }
}


@mixin heading--xl() {

  @include font-serif--display();
  text-transform: uppercase;
  letter-spacing: #{letter-spacing(2.8px, 28px)};
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.20;

  font-size: 28px;

  @include mq('md') {
    font-size: 36px;
  }

  @include mq('xl') {
    font-size: scale-1200(42px);
  }
}

@mixin heading--lg() {
  @include font-serif--display();
  letter-spacing: #{letter-spacing(.56px, 28px)};
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.06;

  font-size: 28px;

  @include mq('md') {
    font-size: scale-1200(36px);
  }

  @include mq('xl') {
    font-size: scale-1200(42px);
    .u-type-subscripts__sub--tm {
      font-size: 30%;
    }
  }
}

@mixin heading--feature-card() {
  @include font-serif--display();
  letter-spacing: #{letter-spacing(.56px, 28px)};
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.06;

  font-size: 28px;

  @include mq('md') {
    font-size: scale-1200(28px);
  }

  @include mq('xl') {
    font-size: scale-1200(34px);
    .u-type-subscripts__sub--tm {
      font-size: 35%;
    }
  }
}

@mixin heading--md() {
  @include font-serif--bold();
  text-transform: lowercase;
  font-variant: small-caps;
  font-weight: $font-weight--bold;
  line-height: .81;
  letter-spacing: #{letter-spacing(1.44px, 18px)};

  // font-style: italic;

  font-size: 18px;

  @include mq('md') {
    font-size: 20px;
  }

  @include mq('xl') {
    font-size: scale-1200(22px);
  }
}

@mixin heading--sm() {
  @include font-serif--bold();

  text-transform: lowercase;
  font-variant: small-caps;
  font-weight: $font-weight--bold;
  line-height: 1.28;
  letter-spacing: #{letter-spacing(.84px, 14px)};

  // font-style: italic;

  font-size: 14px;

  @include mq('md') {
    font-size: 16px;
  }

  @include mq('xl') {
    font-size: scale-1200(18px);
  }
}

@mixin body--xl() {
  @include font-serif--display();
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.25;
  letter-spacing: #{letter-spacing(.5px, 20px)};

  font-size: 20px;

  @include mq('md') {
    font-size: 26px;
  }

  @include mq('xl') {
    font-size: scale-vw-desktop(30px);
    line-height: 1.28;
  }
}

@mixin body--lg() {
  @include font-serif--display();
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: #{letter-spacing(.45px, 18px)};

  font-size: 18px;

  @include mq('md') {
    font-size: 20px;
  }

  @include mq('xl') {
    font-size: scale-1200(22px);
  }
}

@mixin body--md() {
  @include font-serif--display();
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: #{letter-spacing(.4px, 16px)};

  font-size: 16px;

  .u-type-subscripts__sub--tm {
    font-size: 35%;
  }

  @include mq('md') {
    font-size: 18px;
  }

  @include mq('xl') {
    font-size: scale-1200(20px);
  }
}

@mixin body--sm() {
  @include font-serif--display();
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: #{letter-spacing(.4px, 14px)};

  font-size: 14px;

  // @include mq('md') {
  //   font-size: 18px;
  // }

  @include mq('xl') {
    font-size: scale-vw-desktop(14px);
  }
}

@mixin cta--mobile-nav() {
  @include font-sans-serif--bold();
  text-transform: uppercase;

  font-size: 16px;
  letter-spacing: #{letter-spacing(1.6px, 16px)};
}

@mixin cta--lg() {
  @include font-sans-serif--bold();
  text-transform: uppercase;

  font-size: 12.5px;
  letter-spacing: #{letter-spacing(1.75px, 12.5px)};

  @include mq('md') {
    font-size: 14px;
    letter-spacing: #{letter-spacing(1.4px, 14px)};
  }

  @include mq('xl') {
    font-size: scale-1200(14px);
  }
}

@mixin sans-serif--lg() {
  @include font-sans-serif();
  text-transform: uppercase;

  font-size: 12.5px;
  letter-spacing: #{letter-spacing(1.75px, 12.5px)};

  @include mq('md') {
    font-size: 14px;
    letter-spacing: #{letter-spacing(1.4px, 14px)};
  }

  @include mq('xl') {
    font-size: scale-1200(14px);
  }
}

@mixin cta--md() {
  @include font-sans-serif--bold();
  text-transform: uppercase;
  font-size: 12.5px;
  letter-spacing: .12em;

  @include mq('md') {
    font-size: 12.5px;
  }

  @include mq('xl') {
    font-size: scale-1200(12.5px);
  }
}

@mixin sans-serif--md() {
  @include font-sans-serif();
  text-transform: uppercase;
  font-size: 12.5px;
  letter-spacing: .12em;

  @include mq('md') {
    font-size: 12.5px;
  }

  @include mq('xl') {
    font-size: scale-1200(12.5px);
  }
}

@mixin cta--sm() {
  @include font-sans-serif--bold();
  text-transform: uppercase;

  font-size: 11.5px;
  letter-spacing: 0.12em;
}

@mixin sans-serif--sm() {
  @include font-sans-serif();
  text-transform: uppercase;

  font-size: 11.5px;
  letter-spacing: 0.12em;
}

@mixin cta--xs() {
  @include font-sans-serif--bold();
  text-transform: uppercase;
  font-size: 11.5px;
}

@mixin sans-serif--xs() {
  @include font-sans-serif--bold();
  text-transform: uppercase;
  font-size: 11.5px;
}

@mixin copy--fine-print() {
  @include font-serif();
  font-style:italic;
  font-size: 10px;
  letter-spacing: 0.25px;
  line-height: 13px;
}

@mixin heading--og-md {
  -webkit-font-smoothing: antialiased;
  @include font-serif();
  font-variant: small-caps;
  text-transform: lowercase;
  font-size: 1.2em;
  line-height: .9230769231;
}

@mixin copy-dropdown-option() {
  font-size: 22px;

  @include mq('lt-680') {
    font-size: 22px;
  }

  @include body--md();
}

@mixin heading--footer() {
  @include font-serif--bold();

  text-transform: lowercase;
  font-variant: small-caps;
  font-weight: $font-weight--bold;
  line-height: 1;
  // font-style: italic;
  letter-spacing: #{letter-spacing(1.44px, 18px)};
  font-size: 18px;

  @include mq('xl') {
    line-height: 1.28;
    letter-spacing: #{letter-spacing(.84px, 14px)};
    font-size: scale-1200(18px);
  }
}

@mixin body--footer() {
  @include font-serif--display();
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: #{letter-spacing(.45px, 18px)};

  font-size: 18px;

  .u-type-subscripts__sub--tm {
    font-size: 35%;
  }

  @include mq('xl') {
    letter-spacing: #{letter-spacing(.4px, 20px)};
    font-size: scale-1200(20px);
  }
}

@mixin body--copyright() {
  @include font-serif--display();
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: #{letter-spacing(.45px, 18px)};

  font-size: 18px;

  .u-type-subscripts__sub--tm {
    font-size: 35%;
  }

  @include mq('lg') {
    letter-spacing: #{letter-spacing(.35px, 14px)};
    font-size: scale-1200(14px);
  }
}

@mixin body--nutrition-list() {
  @include font-serif--display();
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.25;
  letter-spacing: #{letter-spacing(.45px, 16px)};

  font-size: 16px;

  .u-type-subscripts__sub--tm {
    font-size: 35%;
  }

  @include mq('xl') {
    font-size: scale-1200(18px);
  }
}

@mixin cta--nav() {
  @include font-sans-serif--bold();
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: .12em;

  @include mq('lg') {
    font-size: scale-1200(12.5px);
  }
}

@mixin cta--nav-lg() {
  @include font-sans-serif();
  text-transform: uppercase;

  font-size: 16px;
  letter-spacing: #{letter-spacing(1.6px, 16px)};

  @include mq('md') {
    font-size: 14px;
    letter-spacing: #{letter-spacing(2.5px, 14px)};
  }

  @include mq('xl') {
    font-size: scale-1200(14px);
  }
}

@mixin cta--footer() {
  @include font-sans-serif--bold();
  text-transform: uppercase;

  font-size: 16px;
  letter-spacing: #{letter-spacing(1.6px, 16px)};

  @include mq('lg') {
    letter-spacing: #{letter-spacing(1.4px, 14px)};
    font-size: scale-1200(14px);
  }
}

@mixin heading--nav() {
  @include font-serif--bold();

  text-transform: lowercase;
  font-variant: small-caps;
  font-weight: $font-weight--bold;
  line-height: 1.28;
  letter-spacing: #{letter-spacing(1.44px, 18px)};
  // font-style: italic;
  font-size: 18px;

  @include mq('md') {
    font-size: 16px;
  }

  @include mq('xl') {
    font-size: scale-1200(18px);
  }
}

// Typekit OpenType Features: Small Caps
// scss-lint:disable PropertySpelling
@mixin small-caps {
  font-variant: small-caps;
  text-transform: lowercase;

  // Use CSS.supports API to check for font-feature-settings
  .supports-font-features.wf-active & {
    font-variant: normal;
    font-variant-caps: small-caps; // Firefox 34+
    font-feature-settings: 'smcp'; // Safari 9.1+; Everything else
  }

  // HACK: Superscripts
  sup {
    // top: 0.54em;
    // font-size: 80%;

    vertical-align: top;
    margin-top: 0.2em;
    font-size: 70%;
    display: inline-block;

    &.u-reset-small-caps-sup{
      top: -0.1em;
      font-size: 36%;
    }
  }
}

@mixin reset-small-caps {
  font-variant: normal;
  font-variant-caps: normal;
  text-transform: none;

  .supports-font-features.wf-active & {
    font-variant-caps: initial;
    font-feature-settings: initial;
  }

  // HACK: Superscripts
  sup {
    top: 0.4em;
    font-size: 100%;
  }
}


// Note: Uses `.u-small-caps`
@mixin font-heading {
  color: $heading-color-primary;
  font-weight: $font-weight;
  letter-spacing: tracking(10);
  -webkit-font-smoothing: antialiased;
}

// Note: Uses `.u-reset-small-caps`
@mixin font-subheading {
  font-size: em(16);
  font-weight: $font-weight--medium;
  font-style: italic;
  line-height: math.div(20, 16);
  letter-spacing: tracking(10);
  // -webkit-font-smoothing: subpixel-antialiased;

  @include mq($bp-shrink-fonts) {
    // font-size: em(13);
    font-size: em(14);
  }
}

// Note: Uses `.u-small-caps`
@mixin font-nav-heading {
  letter-spacing: tracking(75);
  text-decoration: none;
}

// TODO: Move to a separate partial (or rename, maybe to callout?)
// Note: Uses `.u-small-caps`
@mixin font-button {
  font-size: em(18);
  letter-spacing: tracking(50);

  @include mq($bp-shrink-fonts) {
    // font-size: em(13);
    font-size: em(16);
  }
}
