@include o-list();
@include o-list__item();

// .o-list {
// }

.o-list__item {
  break-inside: avoid;
}

.o-list--bullets {
  > .o-list__item {
    position: relative;
    margin-left: 15px;
  }
  > .o-list__item::before {
    position: absolute;
    top: -2px;
    left: -15px;
    content: '• ';
  }
}

.o-list--bullets-alt {
  padding-left: 1.125em;
  list-style: disc;

  > li {
    padding-bottom: em(6);
  }
}

.o-list--numbers {
  > .o-list__item {
    counter-increment: list-numbers;
  }

  .o-list__counter::before {
    display: inline-block;
    content: counter(list-numbers) '. ';
  }
}

// scss-lint:disable all
.o-list--numbers-roman {
  > .o-list__item {
    margin-left: em(20);
    counter-increment: list-numbers;

    &::before {
      display: inline-block;
      margin-right: em(5);
      content: counter(list-numbers, lower-roman) '. ';
    }

    ol > .o-list__item {
      counter-increment: list-numbers-2;
      margin-left: em(20);

      &::before {
        display: inline-block;
        content: counter(list-numbers-2, lower-alpha) '. ';
        margin-right: em(5);
      }
    }
  }
}
// scss-lint:enable all

.o-list--leading-zero {
  > .o-list__item {
    counter-increment: list-numbers-zero-pad;
  }

  .o-list__counter::before {
    display: inline-block;
    content: counter(list-numbers-zero-pad, decimal-leading-zero);
  }

  // recipe instructions
  .o-list__item--instructions {
    position: relative;

    @include body--lg();

    .o-list__counter {
      display: grid;
      grid-template-columns: fluid(34px, 38px) 1fr;

      &::before {
        @include sans-serif--lg();
        line-height: 1.8;
        color: $color-brand-gold;
      }
    }

    margin-bottom: scale-1200(34px);
  }
}

.o-list--inline {
  > .o-list__item {
    display: inline-block;
  }
}

.o-list--commas {
  > .o-list__item {
    display: inline-block;
  }
  > .o-list__item:not(:last-child)::after {
    margin-left: -2px;
    content: ', ';
  }
}

.o-list--outline {
  .o-list__content {
    padding-left: 1em;
  }

  // Indent multiline counter text
  .o-list__counter {
    display: table;

    &::before {
      display: table-cell;
      width: 1em;
    }
  }
}

.o-list--borders {

  > .o-list__item {
    border-bottom: 1px solid #211f1c;

    &:nth-last-of-type(1),
    &:nth-last-of-type(2) {
      border-bottom: none;
    }

    &:nth-of-type(even) {
      .o-list__item-inner {
        border-left: 1px solid #211f1c;
      }
    }
  }
}



// TODO: Refactor list hacks
._list-with-more-vertical-space {
  .o-list__item {
    margin-bottom: 0.25em;
  }
  a {
    display: inline-block;
    line-height: 1.15;
  }
}
