.c-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.c-button--box:focus, .c-button--box:active {
  outline: none;
}
.c-button--box:focus-within::after, .c-button--box:active::after {
  content: "-";
  display: block;
  width: 100%;
  border-bottom: 6px solid $color-brown-3;
  position: absolute;
  line-height: 0;
  margin-top: 3rem;
  color: $color-brown-3;
}

@import 'box-legacy';
@import 'circle';
// @import 'play';
@import 'scroll';
@import 'pill';
