.section-two-column {
  position: relative;
}

.section-two-column__content {
  position: relative;
  z-index: 1;

  display: grid;
  grid-template-areas:
    "copy"
    "media";

  row-gap: 8vw;
  column-gap: 4vw;

  @include mq('lg') {
    grid-template-areas: "copy media";
  }

  @include mq('1240') {
    column-gap: scale-1200(88px);
    row-gap: scale-1200(88px);
  }

  padding-left: scale-vw-mobile($page-margins--mobile);
  padding-right: scale-vw-mobile($page-margins--mobile);

  @include mq('xl') {
    padding-left: scale-1200($page-margins--outer);
    padding-right: scale-1200(90px);
  }

  padding-top: scale-1200(76px);
  padding-bottom: scale-1200(174px);
}

.section-two-column__copy-container {
  grid-area: copy;

  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;

  @include mq('lg') {
    align-items: flex-start;
  }
}

.section-two-column__subheading {
  // @include heading--sm();

  @include heading--sm();

  color: $color-brand-gold;

  text-align: center;

  @include mq('lg') {
    @include heading--sm();
    text-align: left;
  }
}

.section-two-column__heading {
  @include heading--lg();

  margin-top: scale-1200(30px);

  text-align: center;

  @include mq('lg') {
    text-align: left;
  }
}

.section-two-column__copy {
  margin-top: scale-1200(30px);
  @include body--lg();

  text-align: center;
  max-width: 80vw;

  @include mq('lg') {
    text-align: left;
    max-width: none;
  }
}

.section-two-column__cta {

  display: flex;
  margin-top: scale-1200(40px);

  a {
    @include button--pill();
    align-self: flex-start;
  }
}

.section-two-column__media {
  grid-area: media;

  @include mq('lg') {
    width: 50vw;
  }

  @include mq('xl') {
    width: scale-1200(824px);
  }
}
