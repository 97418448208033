@use "sass:math";

// .c-copy {
// }

.c-copy--xxs {
  font-size: em(10);
  letter-spacing: tracking(10);
  line-height: math.div(15, 13);
}

.c-copy--sm {
  font-size: em(13);
  letter-spacing: tracking(10);
  line-height: math.div(15, 13);
}

.c-copy--md {
  font-size: em(16);
  letter-spacing: tracking(10);
  line-height: math.div(15, 16);

  @include mq($bp-shrink-fonts){
    font-size: em(13);
    line-height: math.div(15, 13);
  }
}

.c-copy--lg {
  font-size: em(17);
  line-height: math.div(22, 17);
}
.c-copy--xl {
  font-size: em(19);
  line-height: math.div(30, 21);
  letter-spacing: tracking(10);
}
.c-copy--21 {
  font-size: em(21);
  line-height: math.div(30, 21);
  letter-spacing: tracking(10);
  @include mq($bp-shrink-fonts){
    font-size: em(17);
    line-height: math.div(44, 34)
  }
}
.c-copy--xxl {
  font-size: em(24);
  line-height: math.div(30, 21);
  letter-spacing: tracking(10);
  @include mq($bp-shrink-fonts){
    font-size: em(13.5);
  }
}
.c-copy--33 {
  font-size: em(33);
  line-height: math.div(30, 21);
  letter-spacing: tracking(10);
  @include mq($bp-shrink-fonts){
    font-size: em(19);
  }
}

// TODO: Rename as copy modifier
.c-disclaimer {
  margin-bottom: 0.5em;
  font-size: em(13);
  font-style: italic;
  letter-spacing: tracking(10);
  line-height: math.div(15, 13);

  &.c-disclaimer--sans-serif {
    font-style: normal;
    font-size: em(12);
    font-family: 'Arial';
  }
}

.c-number-callout {
  background: $color-black-1;
  margin-left: em(10);
  padding: em(5) 0;
  border: 1px solid $color-gold-1;
  width: em(54);  // fit '33'
  text-align: center;
}

.body--xl{
  @include body--xl();
}
