#email-share-modal {

  &.c-modal__form {

    .c-modal__content-container {
      max-width: scale-1200(500px);
    }

    .c-modal__content {
      padding: scale-1200(75px) 35px;
      background-color: #151310;
      text-align: center;

      @include mq('680') {
        padding: scale-1200(75px);
      }
    }

    .c-modal__close {
      top: 33px;
      right: 22px;
    }

    .c-icon--filigree-reverse-union {
      max-width: 16.46px;
      margin: scale-1200(24px) auto 0;
    }

    h1 {
      color:$color-brand-gold;
      @include heading--md();
    }

    .c-icon--close  {
      svg {
        fill: #fff;
      }
    }

    .c-form__fieldset {
      margin-bottom: scale-1200(38px);
    }

    .c-modal__content, .c-form {
      display:grid;
      grid-template-columns:1fr;
      grid-row-gap: 40px;

      p {
        @include body--md();
        margin:0 auto scale-1200(40px) auto;
      }
    }
  }

  .button--submission {
    margin: 0 auto;
  }
}
