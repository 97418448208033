@use "sass:math";

// TODO: Cleaner "caption" convention for buttons. (May be best to make scroll button a separate component with a `__caption` element.)
._button-caption {
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 200%;
  height: percentage(math.div((45 + 10 + 24), 45)); // Button height + margin + text height
  transform: translateX(-50%);
  border: 0;
  text-align: center;
  text-decoration: none;

  // HACK: Remove caption from hero just above tablet to avoid edge cases where it runs into the play button (takes up a lot of extra space anyway).
  @media (max-width: em(880, 16)) {
    display: none;
  }
}

.c-button--scroll {
  position: relative;
  width: em(90);
  height: em(45);
  color: $color-gold-1;

  &::after,
  &::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: solid transparent;
    content: '';
    pointer-events: none;
  }

  // Black notch
  &::before {
    $size: em(35);
    transform: translate(-50%, 0);
    transition: border-width 200ms ease;
    border-width: $size;
    border-bottom-color: $color-black-1;
  }

  // Gold arrow
  &::after {
    $size: em(6.5);
    margin-left: -$size;
    transform: none;
    transition: border-color 200ms ease;
    border-width: $size;
    border-top-color: currentColor;
    animation: slight-bounce 2000ms ease infinite;
  }

  // scss-lint:disable DeclarationOrder
  @include hover {
    color: $color-gold-3;

    &::before {
      border-width: em(45);
    }
    &::after {
      animation: none;
    }
  }
  // scss-lint:enable DeclarationOrder
}

// Arrow Only Version
.c-button--scroll-arrow {
  @extend .c-button--scroll;
  @include small-caps;
  display: inline-block;
  width: em(90);
  height: em(45);
  color: $color-gold-1;
  font-size: em(20);
  -webkit-font-smoothing: antialiased;
  letter-spacing: 1px;
  text-align: center;
  text-decoration: none;

  &::before {
    content: none;
  }
}

// Pink Arrow Version
.c-button--scroll-pink {
  @extend .c-button--scroll;

  &::after {
    border-top-color: $color-pink-1;
  }
}
