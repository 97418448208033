.share-service {
  position: relative;
}

.share-service__services {

  position: absolute;
  bottom: 10px;
  left: 0;
  transform: translate3d(-10%, 100%, 0);
  width: 128px;

  background: #000;
  padding: 10px;
  border-radius: 10%;

  opacity: 0;
  visibility: hidden;
}

.share-service {
  &.ready {
    .share-service__services {
      visibility: visible;
    }
  }

  &.active {
    .share-service__services {
      opacity: 1;
    }
  }
}

