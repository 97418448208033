@mixin hr($background: $color-brand-gold) {
  // Rule size is based on the default heading font-size (44px)
  width: em(33);
  height: em(2);
  margin: 0.5em auto 1em;
  border: 0;
  background: $background;
}



.c-hr {
  // TODO: Merge with `hr` mixin in reset
  // // Rule size is based on the default heading font-size (44px)
  // width: em(33);
  // height: em(2);
  // // TODO: Finalize vertical margin, else remove and use other components/utilities
  // margin: 0.5em auto;
  // border: 0;
  // background: $background;
  // pointer-events: none;
  @include hr();
}



// ------------------------------
// Alignment
// ------------------------------

.c-hr--left {
  margin-top: em(30); // HACK: Extra margin for "Our Story" headings
  margin-left: 0;
}

// HACK: Extra margin for testimonials on home page
.c-hr--vertical-margin {
  margin-top: em(35);
  margin-bottom: em(40);
}



// ------------------------------
// Size
// ------------------------------

.c-hr--md {
  width: em(20);
  height: em(1);
}

// TODO: Should these use size aliases, or be kept as a separate modifiers?
.c-hr--full {
  width: 100%;
  height: em(1);
}
.c-hr--wide {
  width: 100%;
  max-width: em(230);
  height: em(1);
}



// ------------------------------
// Color
// ------------------------------

.c-hr--red {
  background: $color-brand-red;
}

.c-hr--light {
  opacity: 0.4;
}

.c-hr--textured {
  background: url(cache-ts-url('/img/pinnacle/global/heading-gold-texture.jpg')) cover no-repeat;
}
