.active-list {

  .active-list__item {
    transition: opacity .5s ease-out;
  }

  &.active-list--active {

    .active-list__item {
      opacity: .4;
    }

    .active-list__item--active {
      opacity: 1;
    }
  }
}
