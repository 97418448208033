.c-range {
  input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 1px;
    background: var(--form-range-track);
    // border-radius: 5px;
    // background-image: linear-gradient(#ff4500, #ff4500);
    // background-size: 70% 100%;
    // background-repeat: no-repeat;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background: var(--form-range-handle);
      // cursor: ew-resize;
      // box-shadow: 0 0 2px 0 #555;
      // transition: background .3s ease-in-out;
    }

    &::-webkit-slider-runnable-track  {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }

    &.inverse {
      direction: rtl;
    }
  }
}

.c-range__increment-btn {
  width: 12px;
  height: 12px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.c-range--pill {

  border-radius: 31.5px;
  border: 1px solid var(--form-range-border);

  height: var(--pill-height);

  display: flex;
  justify-content: center;
  align-items: center;

  column-gap: 12px;

  padding-left: 15px;
  padding-right: 15px;

  @include mq('xl') {
    height: scale-1200(44px);
  }
}


.c-range--std {

  min-height: 30px;

  input[type="range"] {
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: var(--form-range-handle);
      border: 4px solid var(--form-range-handle-border);
    }
  }
}

