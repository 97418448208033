.privacy{

  #m-wrapper {
    padding-top: var(--header-height);
  }

  .privacy-content{

    padding-left: var(--page-margins-outer);
    padding-right: var(--page-margins-outer);

    h2{
      color:$color-brand-gold;
      @include heading--sm();
    }
    p > strong{
      color:$color-bone;
      @include heading--md();
    }

  }

  .privacy-content .privacy-sections .privacy-section-content ol,
  .privacy-content .privacy-sections .privacy-section-content ul {
    margin: 0 0 1rem;
    padding-inline: 2.5rem;
  }

  .privacy-content .privacy-sections .privacy-section .privacy-section-content li,
  .privacy-content .privacy-sections .privacy-section .privacy-section-content blockquote,
  .privacy-content .privacy-sections .privacy-section .privacy-section-content pre {
    margin: 0 0 .5rem;
  }

  .privacy-content .privacy-sections .privacy-section .privacy-section-content p {
    margin: 0 0 1rem;
  }

  .privacy-section{
    padding-bottom: scale-1200(30px);
  }
}
