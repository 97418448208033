$desktop-bp: 'md';

.section-twocol-asset {
  display: grid;

  grid-template-columns: 100%;
  grid-template-areas:
    "content"
    "media";
  row-gap: 35px;

  padding-left: var(--page-margins-inner);
  padding-right: var(--page-margins-inner);

  @include mq($desktop-bp) {
    grid-template-columns: 1fr minmax(30px, 76px) 1fr;
    grid-template-areas: "media . content";
  }
}

.section-twocol-asset--content-left {
  @include mq($desktop-bp) {
    grid-template-areas: "content . media";
  }
}

.section-twocol-asset__media-container {
  grid-area: media;
}

.section-twocol-asset__content-container {
  grid-area: content;

  display: flex;
  justify-content: center;
  align-items: center;
}

.section-twocol-asset__content {
  display: flex;
  flex-direction: column;
  align-items: center;

  row-gap: scale-1200(20px);
  @include mq($desktop-bp) {
    row-gap: scale-1200(30px);
    max-width: scale-1200(424px);
  }
}

.section-twocol-asset__media {
  display: grid;

  aspect-ratio: 540 / 640;

  picture, img, video {
    grid-column: 1;
    grid-row: 1;

    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.section-twocol-asset__video--mobile {
  display: block;
  @include mq($desktop-bp) {
    display: none;
  }
}

.section-twocol-asset__video--desktop {
  display: none;
  @include mq($desktop-bp) {
    display: block;
  }
}

.section-twocol-asset__subheading {
  @include heading--md();
  margin-bottom:scale-vw-mobile(37px);
  @include mq($desktop-bp) {
    margin-bottom: scale-1200(40px);
  }
}

.section-twocol-asset__heading {
  @include heading--xl();
  text-align: center;
}

.section-twocol-asset__divider {
}

.section-twocol-asset__copy {
  text-align: center;
  @include body--lg();
  @include mq('xl') {
    max-width: 80%;
  }
}
