@use "sass:math";

$desktop-bp: 'xl';

.search {
  --search-form-height: 75px;
  padding-top: var(--header-full-height);
}

.search__form {
  background: var(--background);

  position: static;
  top: calc(var(--height) - 1px);
  left: 0;
  z-index: 1;

  height: var(--search-form-height);
  width: 100%;

  display: flex;
  justify-content: center;

  // transform: translate3d(0, -100%, 0);
  // transition: transform .5s var(--motion-easing);

  padding-left: var(--page-margins-outer);
  padding-right: var(--page-margins-outer);
}

.search__input-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  max-width: 500px;

  @include mq('xl') {
    max-width: 700px;
  }

  width: 100%;

  .search__actions {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    transform: translate3d(-100%, 0px, 0);
  }

  .search__submit {

    grid-row: 1;
    grid-column: 1;

    width: 12px;
    height: 12px;

    .c-icon--search {
      width: 100%;
      height: 100%;
      display: block;
    }

    visibility: visible;
    pointer-events: auto;

    @include mq($desktop-bp) {
      display: block;
    }
  }

  .search__close {

    grid-row: 1;
    grid-column: 1;

    visibility: hidden;
    pointer-events: none;

    width: 12px;
    height: 12px;

    .c-icon--close {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.search-open--has-results, .search-open--no-results {
  .search__submit {
    visibility: hidden;
    pointer-events: none;
  }

  .search__close {
    visibility: visible;
    pointer-events: auto;
  }
}

.search__input {
  color: var(--color-bone);
  font-size: scale-1200(22px);
  letter-spacing: #{letter-spacing(.25px, 22px)};
  // leading-trim: both;
  // text-edge: cap;
  // font-variant-numeric: lining-nums proportional-nums;
  // font-family: Minion Pro;
  // font-size: 22px;
  // font-style: normal;
  // font-weight: 400;
  // line-height: 133.5%; /* 29.37px */
  // letter-spacing: 0.25px;
  background: transparent;
  border-bottom: 0.5px solid var(--color-bone);
  padding-bottom: scale-1200(10px);
  padding-right: scale-1200(40px);

  appearance: none;

  &::placeholder {
    color: $color-bone;
    opacity: .5;
  }

  width: 100%;
  @include mq($desktop-bp) {
    width: scale-1200(1188px);
  }
}

.search__results {
  background: var(--background);

  position: static;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;

  padding-top: calc(var(--height) + var(--search-form-height));
  padding-left: var(--page-margins-inner);
  padding-right: var(--page-margins-inner);
  // transform: translate3d(0, -100%, 0);
  // transition: transform .5s var(--motion-easing);

  @include mq('xl') {
    padding-right: calc(var(--page-margins-inner) - 20px); // account for scroll bar
  }


  > .search__view-all {
    // display: block;

    @media (min-width: 1200px) and (max-height: 600px) {
      display: none;
    }

    // @media (min-height: 900px) {
    //   display: inline-block;
    // }
  }
}

.search__intro-message {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include body--xl();
  transition: opacity .2s ease-out;

  p {
    max-width: 90%;
    text-align: center;

    margin-bottom: 70px;

    @include mq('lg') {
      max-width: 80%;
    }

    @include mq('xl') {
      max-width: 63%;
    }
  }

  .has-searched & {
    display: none;
  }
}

.c-modal__close.search__close-search {
  position: static;
}

.search__no-results-message {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include body--xl();
  transition: opacity .2s ease-out;

  p {
    max-width: 90%;
    text-align: center;

    @include mq('lg') {
      max-width: 80%;
    }

    @include mq('xl') {
      max-width: 63%;
    }
  }
}

.search__results-tab-panels {
  margin-bottom: 0;//scale-1200(78px);
  // overflow-y: auto;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .9);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity .2s ease-out;
    pointer-events: none;
    z-index: 1;
  }

  position: relative;

  .has-searched & {
    display: block;
  }

  .search-open--load-results & {
    pointer-events: none;
    &::after {
      opacity: 1;
      pointer-events: auto;
    }
  }

  // @media (min-height: 900px) {
  //   min-height: 462px;
  // }
}

.search__results-tabs {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-self: center;

  // visibility: hidden;

  margin-bottom: scale-1200(35px);

  > div {
    display: flex;
    column-gap: scale-1200(20px);
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 768px) {
      width: auto;
      justify-content: flex-start;
    }
  }

  a {
    @include cta--md();
    color: var(--color-brand-gold);
    text-decoration: none;

    &:focus, &:active {
      outline: none;
    }

    // Keep the selected tab full opacity
    &.active-list__item[aria-selected="true"] {
      opacity: 1;
    }
  }

  .search__view-all {
    display: none;
    align-self: center;


    @media (min-width: 1200px) and (max-height: 600px) {
      display: inline-block;
    }
  }

  .has-searched & {
    visibility: visible;
  }
}

.search__results-products {
  display: grid;

  padding-bottom: scale-1200(78px);

  grid-template-columns: repeat(2, 1fr);
  column-gap: 9px;
  row-gap: 12px;

  @include mq('lg') {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;

    column-gap: scale-1200(30px);
    row-gap: scale-1200(30px);

    padding-right: 10px;
  }
}

.search__results-recipes {
  display: grid;

  padding-bottom: scale-1200(78px);

  grid-template-columns: 1fr;
  gap: 15px;

  @include mq(md) {
    grid-template-columns: repeat(3, 1fr);
    gap: scale-1200(20px);
    padding-right: 10px;
  }
}

.search__results-pages {

  padding-bottom: scale-1200(78px);

  ul {
    list-style: none;
    list-style-type: none;
    columns: 20rem auto;
    column-gap: scale-1200(130px);
  }

  li {
    margin-bottom: scale-1200(20px);
  }

  a {
    @include body--lg();
    text-decoration: none;
    color: var(--brand-bone);

    &:focus, &:hover {
      text-decoration: underline;
    }
  }
}

.search__result-card {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  position: relative;

  .search__result-card-img {
    flex-shrink: 0;
    height: 200px;

    height: 20.305556vw;

    @media (min-height: 900px) {
      height: 24.305556vw;
    }


    img, video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h2 {
    @include body--md();
    color: var(--color-bone);
  }

  a {
    text-decoration: none;
    @include full-area-cta();
  }
}

.search__product {
  .search__result-card-img {
    aspect-ratio: 275 / 350;
  }
}


.search__recipe {
  .search__result-card-img {
    aspect-ratio: 572 / 350;
  }
}

// Enhanced tab styles
.search__results-tabs {
  button[aria-selected="true"] {
    opacity: 1;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--color-brand-gold);
    }
  }

  button:not([aria-selected="true"]) {
    opacity: 0.7;

    &:hover {
      opacity: 0.9;
    }
  }
}

// Make sure product grid maintains proper layout
.search__results-products {
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 9px;
    row-gap: 12px;

    @include mq('lg') {
      grid-template-columns: repeat(4, 1fr);
      column-gap: scale-1200(30px);
      row-gap: scale-1200(30px);
    }

}

// Make sure recipe grid maintains proper layout
.search__results-recipes {
    display:grid;
    grid-template-columns: 1fr;
    gap: 15px;

    @include mq(md) {
      grid-template-columns: repeat(3, 1fr);
      gap: scale-1200(20px);
    }

}

// Fix for recipe page visibility issues
.recipes-listing {
  .search__results {
    transform: translate3d(0, 0, 0) !important;

    &[style] {
      // Override any inline styles that might be interfering
      opacity: 1 !important;
      visibility: visible !important;
    }
  }
}


// Ensure only the selected tab panel is visible
[role="tabpanel"]:not(.selected) {
  display: none;
  visibility: hidden;
}

// Add selected class for the active tab panel
[role="tabpanel"].selected {
  display: grid;
  visibility: visible;
}


