.recipe-card {
  --recipe-card-aspect-ratio: 378 / 232;

  position: relative;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content;
  row-gap: scale-1200(20px);

  @include body--md();
  text-decoration: none;
  color: var(--color-bone);

  &:focus {
    text-decoration: underline;
  }

  &:hover {
    .recipe-card__view {
      opacity: 1;
    }

    .recipe-card__media {

      img, video {
        transform: scale(1.1);
      }

      &::after {
        opacity: 1;
      }
    }

  }
}

.recipe-card--has-hover {
  .recipe-card__media img {
    opacity: 1;
    transition: .2s opacity ease-out;
  }

  &:hover {
    .recipe-card__media img {
      opacity: 0;
    }
  }
}

.recipe-card__media {

  position: relative;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;

  overflow: hidden;

  aspect-ratio: var(--recipe-card-aspect-ratio);

  img, video {

    grid-row: 1;
    grid-column: 1;

    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .2s ease-out;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background: var(--recipe-card-hover-overlay);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    transition: opacity .2s ease-out;
    border-bottom: 8px solid var(--recipe-card-hover-border);
  }
}

.recipe-card__flags {
  position: absolute;
  top: scale-1200(16px);
  right: scale-1200(16px);
  z-index: 2;

  width: 100%;

  display: flex;
  flex-wrap: nowrap;
  column-gap: 10px;
  justify-content: flex-end;
}

.recipe-card__caption {
  @include body--md();

  > span {
    display: block;
  }
}

.recipe-card__title {
  max-width: 95%;
}

.recipe-card__cook-time {
  @include cta--sm();
  color: var(--color-brand-gold);
  margin-top: 5px;
}

.recipe-card__view {

  opacity: 0;
  transition: opacity .2s ease-out;

  display: flex;
  justify-content: center;
  align-items: center;

  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;

  width: scale-1200(57.5px);
  height: scale-1200(57.5px);

  text-decoration: none;

  &::after {
    content: '';

    width: 100%;
    height: 100%;

    border: 1px solid var(--color-bone);

    transform: rotate(-45deg);
    transition: transform .2s var(--motion-easing);

    position: absolute;
    top: 0;
    left: 0;
  }

  span {
    @include cta--xs();
    color:  var(--color-bone);
  }
}
