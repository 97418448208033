.cursor {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  visibility: hidden;

  &.active {
    visibility: visible;
    &.disabled-by-child-cursor {
      visibility: hidden !important;
    }
  }

  &.disable-cursor-scroll {
    display: none !important;
  }
}

.cursor__image {
  transform: translate3d(-50%, -50%, 0);
}

.cursor-disabled {
  cursor: none;
}

#cursor-arrow {

  color: $color-brand-gold;

  .cursor__content {
    transform: translate3d(0, -50%, 0);
    top: 0;
    position: absolute;
    left: 100%;
    min-width: 33vw;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  // .cursor__content--flipped {
  //   left: auto;
  //   right: 175%;
  //   align-items: flex-end;
  // }

  .cursor__headline {
    @include cta--lg();
    color: $color-bone;
  }

  // .cursor__description {
  //   color: $color-bone--50-opacity;
  //   @include body--sm();
  //   font-style: italic;
  // }
}

#cursor-drag-drop {

  @media (hover: none) {
    display: none !important;
  }

  .cursor__image {
    opacity: 1;
  }

  .cursor__image.cursor__image--moving {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  &.is-moving {
    .cursor__image {
      opacity: 0;
    }

    .cursor__image.cursor__image--moving {
      opacity: 1;
    }
  }
}
