@import '../base/common';
@import '../components/typography/hr';

.dynamic-page {

  #m-wrapper {
    padding-top: var(--header-height);
  }

  main {
    padding: scale-1200(100px) 0;
  }

  h1.section-header__heading  {
    @include heading--lg();
    margin-bottom: scale-1200(40px);
  }

  .section-header + hr {
    margin-bottom: scale-1200(40px);
  }

  .dynamic-pages__body {

    p {
      margin-bottom: $gutter-xs;
    }

    ul {
      list-style: disc;
      list-style-position: inside !important;
    }

    ol {
      list-style-position: inside !important;
      list-style: decimal-leading-zero;
    }

    ul {
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      padding-inline-start: 1em;
      list-style-position: outside !important;

      li {
        margin-bottom: $gutter-xs;

        ul li, ol li {
          margin-bottom: .5em;
        }
      }
    }

    table {
      border: 1px solid;
      width: 100% !important;

      @include mq('680') {
        width: 80% !important;
      }

      @include mq('lg') {
        width: 70% !important;
      }
    }

    table td, table th {
      border-bottom: 1px solid;
      border-right: 1px solid;
      padding-left: 10px;
      padding-right: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    table th {
      padding-top: 15px;
      padding-bottom: 10px;
      text-align: left !important;
    }

    table > tbody > tr:first-of-type td {
      margin-bottom: 0;
      display: table-cell;
    }

  }
}

.product-recall {

  --body-background: rgb(235, 234, 234);
  --body-color: #211F1D;

  main {
    background-color: var(--body-background);
    color: var(--body-color);
    font-size: 17px;
  }

  .o-sidebar-layout__content {
    padding-left: var(--page-margins-outer);
    padding-right: var(--page-margins-outer);
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 853px;
    @include mq('lg') {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .o-sidebar-layout__sidebar {
    display: none;
  }

  .o-sidebar-layout__content {
    h1, h2, h3, h4, h5 {
      color: var(--body-color);
    }
  }

  &.dynamic-page .dynamic-pages__body {

    h1 {
      text-align: center;
    }

    h2 {
      text-transform: none;
      font-variant: normal;
      margin-bottom: 10px;
      font-size: 1.2em;
      font-weight: bold;
      text-align: left;
    }

    p, ul, div {
      text-align: left;
    }

    a {
      color: revert !important;
    }

    .c-button--box-fill {
      color: var(--body-background);

      display: flex;
      flex-wrap: nowrap;
      gap: 1.7em;

      &:focus {
        outline: none;
      }

      span:last-of-type {
        display: flex;
        align-items: center;
      }

      &::after, &:active::after {
        content: '';
        width: 1px;
        height: 100%;
        border-right: 1px solid currentColor;
        display: block !important;
        opacity: 1 !important;
        position: absolute;
        right: 2.5em;
        top: 0;
        margin-top: 0;
        color: currentColor;
        border-bottom: 0;
      }

      svg {

        width: 12px;
        height: 12px;

        @include mq('md') {
          width: 15px;
          height: 15px;
        }

        display: flex;
        align-items: center;
      }

      padding: .8em;
      font-size: .8em;
      @include mq('md') {
        font-size: 1em;
      }
    }

    table {
      width: 100% !important;
      color: var(--body-color) !important;
      tr, td {
        height: auto !important;
        color: var(--body-color) !important;
      }
    }

    .resources-block {
      ul {
        list-style: none;
        list-style-type:  none;
        padding: 0;
        margin: 0;

        li {
          margin-bottom: .5em;
        }
      }
    }

    .product-list-cta-wrapper {
      text-transform: lowercase;
      font-variant: small-caps;
      font-size: 1.5em;
    }

    .info-block {
      border: 1px solid #AB965D;
      margin-bottom: $gutter-sm;

      .info-block__header {
        background: #AB965D;
        h3 {
          color: #FBF6EA;
          text-transform: none;
          font-variant: normal;
          font-size: 1.1em;
          text-align: left;
        }

        padding: 1.1em;
      }

      .info-block__content {
        padding: 1.1em;
        ul {
          li {
            margin-bottom: .5em;
          }
        }
      }
    }

    .resources-block {
      border: 1px solid #AB965D;
      padding: 1.1em;
    }
  }
}
