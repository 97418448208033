// 2024 Site Color Palate
$color-brand-gold: #AB965D;
$color-brand-gold--75-flat: #c0b085;
$color-brand-gold--50-flat: #d5caae;
$color-brand-gold--25-flat: #eae5d6;
$color-brand-gold--75-opacity: rgba($color-brand-gold, 75%);
$color-brand-gold--50-opacity: rgba($color-brand-gold, 50%);
$color-brand-gold--25-opacity: rgba($color-brand-gold, 25%);

$color-brand-legal: #BBAE96;

$color-bone: #F7EEDF;
$color-bone--75-flat: #f5e9d2;
$color-bone--50-flat: #f9f0e2;
$color-bone--25-flat: #fcf7f0;
$color-bone--75-opacity: rgba($color-bone, 75%);
$color-bone--50-opacity: rgba($color-bone, 50%);
$color-bone--25-opacity: rgba($color-bone, 25%);
$color-bone--15-opacity: rgba($color-bone, 15%);

$color-black: #000000;
$color-black--75-flat: #404040;
$color-black--50-flat: #808080;
$color-black--25-flat: #bfbfbf;
$color-black--90-opacity: rgba($color-black, 90%);
$color-black--85-opacity: rgba($color-black, 85%);
$color-black--80-opacity: rgba($color-black, 80%);
$color-black--75-opacity: rgba($color-black, 75%);
$color-black--50-opacity: rgba($color-black, 50%);
$color-black--25-opacity: rgba($color-black, 25%);

$color-burnt-cacao: #34302B;
$color-burnt-cacao--75-flat: #676460;
$color-burnt-cacao--50-flat: #999795;
$color-burnt-cacao--50-over-black: #0f0f0e;
$color-burnt-cacao--25-flat: #cccbca;
$color-burnt-cacao--75-opacity: rgba($color-burnt-cacao, 75%);
$color-burnt-cacao--50-opacity: rgba($color-burnt-cacao, 50%);
$color-burnt-cacao--25-opacity: rgba($color-burnt-cacao, 25%);

$color-brand-red: #A6192E;//#C31F39;
$color-brand-red--75-flat: #bc5362;
$color-brand-red--50-flat: #d28b96;
$color-brand-red--25-flat: #e9c5cb;
$color-brand-red--75-opacity: rgba($color-brand-red, 75%);
$color-brand-red--50-opacity: rgba($color-brand-red, 50%);
$color-brand-red--25-opacity: rgba($color-brand-red, 25%);

$color-brand-red-gradient: linear-gradient(to right,  #730010 0%,#b80d31 50%,#730010 100%);


$color-misc-copyright: #81796F;
$color-misc-nav-image: #131212;






// Legacy color variables


// Use regex to find stray colors in the project's Sass:
// #[a-zA-Z0-9]+

$color-black-1  : #000;
$color-black-2a : #1a1917; // Mobile header shift when off-canvas-nav is open, also `.c-product-category-card`
$color-black-2  : #211f1d; // Header, footer, etc.
$color-black-2b : #272725; // Mobile primary nav link border color, search form border, and subheading background
$color-black-3  : #2d2a28; // Platinum Black

$color-gray-1:  #827a70;  // Low-level (e.g., footers, disclaimers)
$color-gray-2:  #a7a49e;  // Default color (body copy, etc.)
$color-gray-3:  #cdc9c3;  // Headings and brighter body copy (e.g., intro text)
$color-gray-4:  #e0e0e0;  // Logo text color; Hero headings color
$color-gray-5:  #404040;  // Platinum Gray
$color-gray-6:  #272727;  // Borders
$color-gray-7:  #525050;  // Another Platinum Gray
$color-gray-8:  #d2d2d0;  // Another Platinum Gray
$color-gray-9:  #7b7c7d;  // Another Platinum Gray
$color-gray-10: #e8e4e0;  // Another Platinum Gray
$color-gray-11: #474747;
$color-gray-12: #757575;
$color-gray-13: #eef3e9;
$color-gray-14: #979797;
$color-gray-15: #1D1C1B;
$color-gray-16: #141211;

$color-white-1: #fff;
$color-white-2: #f1f1ee;
$color-white-3: #cec3b7; // Platinum off-white

$color-brown-1: #3f3e3c; // Mobile nav border + header arrow bullets
$color-brown-2: #494540; // Dropdown backgrounds
$color-brown-3: #54504b; // Hover for $color-brown-2
$color-brown-4: #332518; // Platinum brown

$color-gold-1: #8a7449;  // Secondary link color
$color-gold-2: #ab965d;  // Default link color
$color-gold-3: #c3aa86;  // Hover for $color-gold-1, $color-brand-gold, and sometimes $color-gray-2
$color-gold-4: #d5c5a5;  // Hover for $color-brand-gold (primary nav only)
$color-gold-5: #2f2b21;  // Platinum borders
$color-gold-6: #3f3727;  // Platinum Borders Alt
$color-gold-7: #ab9661;  // Homegating gold-box
$color-gold-8: #8d7249;  // Simplicity gold-box
$color-gold-9: #A28D56; // How2Charcuterie

$color-red-2: #a9271b;   // Nav active state
$color-red-3: #71181A;
$color-red-error: #DC3323; // ADA High contrast form error
// October Breast Cancer Awareness Pinks
$color-pink-1: #e371ac;

$color-salmon-1: #ef896c;


// ------------------------------
// Aliases
// ------------------------------

$background-primary    : $color-black-1;

$color-primary         : $color-gray-2;
$color-secondary       : $color-brand-gold;

$link-color            : $color-brand-gold;
$link-color-hover      : $color-gold-3;

$heading-color-primary : $color-gray-3;




// 2024 Site Color Palate
$color-bone: #F7EEDF;
$color-bone--75-flat: #f5e9d2;
$color-bone--50-flat: #f9f0e2;
$color-bone--25-flat: #fcf7f0;
$color-bone--75-opacity: rgba($color-bone, 75%);
$color-bone--50-opacity: rgba($color-bone, 50%);
$color-bone--25-opacity: rgba($color-bone, 25%);
$color-bone--15-opacity: rgba($color-bone, 15%);
