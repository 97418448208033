// TODO: Merge common styles with .c-search (in header)

.c-form {
  --input-outline-color: #{$color-bone};
  --input-outline-focus-color: #{rgba($color-bone, .8)};
  --input-error-color: #{$color-brand-red};
  --input-text-color: #{$color-bone};
  --input-height: 42px;
}

.c-form__fieldset {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.c-form__field {
  display: block;
  flex: 1 1 auto;
  width: 100%;
}

.c-form__field-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > .c-form__error {
    color: var(--input-error-color);
    @include cta--sm();
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.c-form__submit {
  flex: 0 0 auto;
}

.c-form__input {
  display: block;
  width: 100%;
  padding: em(5) em(3);
  background: transparent;
  color: $color-gray-2;
  // margin-left: 1em;
  // font-size: em(20);
  // line-height: (math.div(23,20));

  letter-spacing: .15em;
  @include placeholder {
    color: $color-gray-1;
    font-style: italic;
  }
}

.c-form__submit {
  display: flex;
  align-items: center;
  padding: em(5) em(1);
  padding-left: em(12);
  color: $color-gold-1;

  @include hover {
    color: $color-gold-3;
  }
}


// ------------------------------
// Errors
// ------------------------------

// Structure
.c-form__error {
  display: none;
}
.c-form__field-wrapper.is-invalid > .c-form__error,
.c-form__input.is-invalid + .c-form__error,
.c-form.has-error > .c-form__error,
.c-checkbox__input.is-invalid ~ .c-checkbox__copy .c-form__error,
.c-radio__input.is-invalid ~ .c-radio__copy .c-form__error,
.c-radio__group.is-invalid .c-form__error {
  display: block;
}

// Skin
.c-form__error {
  margin-top: 0.5em;
  // color: $color-red-error;
  color: #d7354d;
  font-size: 90%;
}



// ------------------------------
// Themes
// ------------------------------

.c-form--vertical {
  flex-wrap: wrap;


  .c-form__fieldset {
    flex-direction: column;
  }

  // .c-form__field {
  //   border-bottom: 1px solid $color-brand-gold;
  // }
}



// ------------------------------
// States
// ------------------------------

// NOTE: Form transitions are now handled via JS to avoid issues with overflowing height.

.c-form {
  transition:
    max-height 300ms ease-in-out,
    opacity 300ms ease;
  opacity: 1;

  // &.is-submitted {
  // }
}

.c-form__success-message {
  max-height: 0;
  transition:
    max-height 400ms ease-in-out,
    opacity 400ms ease;
  opacity: 0;
  overflow: hidden;

  // &.is-active {
  // }
}

.c-form__field {
  position: relative;
  margin-bottom: 10px;

  .c-form__label-text {
    position: absolute;
    top: 0;
    transform: translate3d(0, 0, 0);
    display: block;
    pointer-events: none;
    // opacity: 0;
    transition: .1s opacity ease-out, .1s transform ease-out;
    transform-origin: left;
    @include cta--sm();
  }

  &.has-interaction {
    .c-form__label-text {
      transform: translate3d(0, calc(-100% - 5px), 0);
      pointer-events: auto;
      // opacity: 1;
    }
  }
}

// ------------------------------
// JavaScript Hooks
// ------------------------------

// Disable submit buttons while waiting on a response
[class*="js-form"] button[type="submit"][disabled] { // stylelint-disable-line
  cursor: wait;
  opacity: 0.5;
}



// ------------------------------
// Imports
// ------------------------------

@import 'checkbox';
@import 'radio';
@import 'select';
@import 'textarea';
@import 'range';
@import 'input';
