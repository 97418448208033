$desktop-bp: 'md';

.section-full-asset {
  display: grid;

  grid-template-columns: 100%;
  grid-template-areas:
    "content"
    "media";
  row-gap: 35px;

  @include mq($desktop-bp) {
    padding-left: var(--page-margins-outer);
    padding-right: var(--page-margins-outer);
  }

  @include mq($desktop-bp) {
    grid-template-columns: 1fr;
  }
}

.section-full-asset__media-container {
  grid-area: media;

  @include mq($desktop-bp) {
    grid-row: 1;
    grid-column: 1;
  }
}

.section-full-asset__content-container {
  grid-area: content;

  position: relative;
  z-index: 1;

  @include mq($desktop-bp) {
    grid-row: 1;
    grid-column: 1;
  }

  display: flex;
  justify-content: center;
  align-items: center;
}

.section-full-asset__content {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-left: var(--page-margins-outer);
  padding-right: var(--page-margins-outer);

  row-gap: scale-1200(20px);
  @include mq($desktop-bp) {
    row-gap: scale-1200(30px);
    max-width: 575px;
    padding-left: 0;
    padding-right: 0;
  }

  position: relative;
}

.section-full-asset__media {

  --section-full-asset-overlay-background: rgba(48, 24, 2, 0.45);
  --section-full-asset-overlay-blend-mode: multiply;

  display: grid;

  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: var(--section-full-asset-overlay-full);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &::after {
    content: '';
    width: 80%;
    height: 100%;
    background: var(--section-full-asset-overlay-background);
    mix-blend-mode: var(--section-full-asset-overlay-blend-mode);
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    filter: blur(75px);
    transform: translate3d(-50%, -50%, 0);
  }

  &.section-full-asset__media--overlay-a {
    --section-full-asset-overlay-background: rgba(48, 24, 2, 0.45);
    --section-full-asset-overlay-blend-mode: multiply;
    --section-full-asset-overlay-full: rgba(0, 0, 0, .12);
  }

  &.section-full-asset__media--overlay-b {
    --section-full-asset-overlay-background: rgba(0, 0, 0, 0.35);
    --section-full-asset-overlay-blend-mode: multiply;
    --section-full-asset-overlay-full: rgba(0, 0, 0, .12);
  }

  &.section-full-asset__media--overlay-c {
    --section-full-asset-overlay-background: rgba(0, 0, 0, 0.15);
    --section-full-asset-overlay-blend-mode: multiply;
    --section-full-asset-overlay-full: rgba(0, 0, 0, .12);
  }

  &.section-full-asset__media--overlay-dark {
    --section-full-asset-overlay-background: rgba(48, 24, 2, 0.45);
    --section-full-asset-overlay-blend-mode: multiply;
    --section-full-asset-overlay-full: rgba(0, 0, 0, .4);
  }

  aspect-ratio: 345 / 400;
  @include mq($desktop-bp) {
    aspect-ratio: 1276 / 648;
  }

  picture, img, video {
    grid-column: 1;
    grid-row: 1;

    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.section-full-asset__video--mobile {
  display: block;
  @include mq($desktop-bp) {
    display: none;
  }
}

.section-full-asset__video--desktop {
  display: none;
  @include mq($desktop-bp) {
    display: block;
  }
}

.section-full-asset__subheading {
  @include heading--md();
}

.section-full-asset__heading {
  @include heading--xl();
  text-align: center;
}

.section-full-asset__divider {
}

.section-full-asset__copy {
  text-align: center;
}
