.social-nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;


  column-gap: fluid(6px, 10px);
  flex-wrap: wrap;
  @include mq('lg') {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  a {
    transform: none;
    transition: none;
    &:hover {
      text-decoration: none;
      transform: none;
    }
  }

  .c-icon--social {

    box-sizing: content-box;

    width: scale-1200(35.87px);
    height: scale-1200(35.87px);

    &.social-facebook {
      width: 18.889px;
      height: 30.92px;
      padding: 6.213px 9.068px 6.356px 7.766px;

      @include mq('lg') {
        padding: 4px 5.838px 4.093px 5px;
        width: scale-1200(12.162px);
        height: scale-1200(19.907px);
      }
    }

    &.social-instagram {
      padding: 6.213px 4.837px 6.076px 7.452px;
      width: 31.201px;
      height: 31.201px;

      @include mq('lg') {
        padding: 4px 3.114px 3.912px 4.798px;
        width: scale-1200(20.088px);
        height: scale-1200(20.088px);
      }
    }

    &.social-youtube {
      width: 41.213px;
      height: 28.724px;
      padding: 7.766px 5.383px 6.999px 6.213px;
      @include mq('lg') {
        padding: 5px 3.465px 4.506px 4px;
        width: scale-1200(26.535px);
        height: scale-1200(18.494px);
      }
    }

    &.social-pinterest {
      width: 31.222px;
      height: 31.222px;
      padding: 6.213px 5.803px 6.054px 3.358px;

      @include mq('lg') {
        padding: 4px 3.736px 3.898px 2.162px;
        width: scale-1200(21.102px);
        height: scale-1200(21.102px);
      }
    }

    &.social-x {
      width: 25px;
      height: 25px;
      padding: 6.213px 5.803px 6.054px 3.358px;

      @include mq('lg') {
        padding: 4px 3.736px 3.898px 2.162px;
        width: scale-1200(18px);
        height: scale-1200(18px);
      }
    }

    &.social-tiktok {
      width: 31.222px;
      height: 31.222px;
      padding: 6.213px 5.803px 6.054px 3.358px;

      @include mq('lg') {
        padding: 4px 3.736px 3.898px 2.162px;
        width: scale-1200(21.102px);
        height: scale-1200(21.102px);
      }
    }
  }
}
