@use "sass:math";

.footer {

  --footer-spacing-top: 45px;
  --footer-spacing-bottom: 50px;
  --footer-spacing-right: var(--page-margins-outer);
  --footer-spacing-left: var(--page-margins-outer);

  @include mq('1040') {
    --footer-spacing-top: 55px;
    --footer-spacing-right: #{scale-1200(170px)};
  }

  background: var(--footer-bg-color);

  position: relative;
  z-index: 1;

  padding-top: var(--footer-spacing-top);
  padding-bottom: var(--footer-spacing-top);
  padding-left: var(--footer-spacing-left);
  padding-right: var(--footer-spacing-right);
}

.footer__content {

  display: grid;
  grid-template-rows: min-content min-content;

  // column-gap: scale-vw-mobile($spacing-element-horizontal-min);
  column-gap: scale-1200(60px);

  @include mq('lg') {
    row-gap: 0;
  }

  grid-template-columns: 1fr;
  grid-template-areas:
    "logo"
    "navigation"
    "newsletter"
    "social"
    "copyright";

  @include mq('lg') {
    grid-template-columns: scale-1200(430px) 1fr;
    grid-template-areas:
      "logo navigation"
      "newsletter navigation"
      "social copyright";
  }
}

.footer__social-nav {
  grid-area: social;
}

.footer__newsletter {
  grid-area: newsletter;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  height: min-content;

  p {
    text-align: center;
    margin-bottom: scale-1200(20px);
    @include body--footer();
  }

  @include mq('lg') {
    align-items: flex-start;

    p {
      text-align: left;
    }
  }
}

.footer__contact {
  grid-area: navigation;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 50px;
  width:75vw;
  @include mq('md') {
    width:100%;
    padding-left: scale-1200(38px);
  }

  h3 {
    @include heading--footer();
    margin-bottom: scale-1200(16px);
  }

  p {
    text-align: center;
    margin-bottom: scale-1200(25px);
  }

  .footer__contact-btn {
    @include cta--footer();
    color: var(--color-brand-gold);
  }

  @include mq('md') {
    justify-content: flex-start;
    align-items: flex-start;

    p {
      text-align: left;
    }
  }
}

.footer__logo {
  grid-area: logo;

  margin: 0 auto 37px;

  @include mq('lg') {
    margin: 0;
  }

  img {
    max-width: 83px;
  }
}

.footer__navigation {
  grid-area: navigation;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
  column-gap: scale-1200(20px);

  @include mq('md') {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  h4 {
    @include heading--footer();
    color: var(--footer-navigation-color);
    margin-bottom: scale-1200(29px);
  }

  p {
    @include body--footer();
  }
}

.footer__navigation-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  margin-bottom: scale-vw-mobile(50px);
  flex-basis: 45%;

  @include mq('md') {
    flex-basis: 50%;
    justify-content: flex-start;
  }

  @include mq('lg') {
    margin-bottom: 0;
  }

  h3 {
    @include heading--footer();
    margin-bottom: scale-1200(16px);
  }
}

.footer__copyright {
  grid-area: copyright;

  margin-top: scale-1200(48px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  height: min-content;

  @include mq('1160') {
    flex-direction: row;
    align-items: start;
  }

  a{
    color:transparent;

    svg{
      color:initial;
    }
  }

  a.sitemap {
    @include cta--footer();
    color: $color-brand-gold;
  }

  p {

    display: inline;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;

    order: 2;
    margin-top: scale-vw-mobile(50px);

    @include mq('lg') {
      display: flex;
      order: 0;
      white-space: pre;
      margin-top: 0;
      margin-bottom: 0;
      br.copyright-break {
        display: none;
      }
    }

    @include body--copyright();
    color: $color-brand-legal;
    text-align: center;

    > span {
      order: 2;

      @include mq('lg') {
        order: 1;
      }
    }

    a {
      color: $color-brand-legal;
      order: 2;
      flex-basis: 100%;

      @include mq('lg') {
        flex-basis: auto;
        order: 1;
      }
    }

    a.sitemap {
      flex-basis: 100%;
      order: 1;

      @include cta--footer();
      color: $color-brand-gold;
      // text-decoration: none;

      @include mq('lg') {
        order: 2;
        flex-basis: auto;
        margin-left: fluid(15px, 36px);
      }
    }
  }
}

.footer__lang-links {
  list-style: none;
  list-style-type: none;

  display: flex;
  column-gap: 15px;

  margin-bottom: 28px;

  @include mq('md') {
    margin-bottom: 0;
  }

  a {
    @include cta--footer();
    // text-decoration: none;
    color: var(--footer-navigation-color);

    &:hover, &:focus {
      text-decoration: underline;
    }
  }

}

.footer__social-links {
  padding-top: 22px;

  @include mq('md') {
    padding-top: scale-1200(20px);
  }

  .social-nav {
    justify-content: center;
    @include mq('lg') {
      justify-content: flex-start;
    }

    li > a {
      color: var(--color-brand-gold);
    }
  }
}

.footer-links__list {

  display: flex;
  flex-direction: column;
  row-gap: 10px;

  a {
    @include body--footer();
    text-decoration: none;
    color: var(--footer-navigation-color);

    // Help with ADA
    &:focus {
      text-decoration: underline;
    }
  }
}
