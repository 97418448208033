@use "sass:math";

/*doc
---
title: Modals
name: modals
category: Components
---

TODO: Document markup and CSS/JS separation.

- body.modal-open

- js-modal-carousel
- js-modal-carousel__item

- c-modal
- c-modal--align-top
- c-modal__content
- c-modal__content-container

- c-modal-carousel
- c-modal-carousel__item
- c-modal-carousel__nav
- c-modal-carousel__nav-item
- c-modal-carousel__nav-item--next
- c-modal-carousel__nav-item--previous

## States for .c-modal-carousel

- is-active

## States for .c-modal-carousel__item

- is-current
- is-inactive-next
- is-inactive-next-off
- is-inactive-previous
- is-inactive-previous-off
- is-not-transitionable
- is-transitionable

## States for .c-modal-carousel__nav-item

- is-inactive

*/

$gutter: 10px;

// scss-lint:disable QualifyingElement
body.modal-open {
  overflow: hidden;
}

body.modal-open {
  &::before {
    z-index: 1100;
  }

  &.modal-open--with-nav {
    &::before {
      z-index: 999;
    }
  }

  @include body--show-backdrop();
}
// scss-lint:enable QualifyingElement



// --------------------
// Modal
// --------------------

.c-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  padding: $gutter-md 0;
  transition: opacity 0.200s ease;
  outline: 0;
  opacity: 0;
  visibility: hidden;
  /* ADA specifies this as no display */
  display:none;

  z-index: 1100;
  -webkit-overflow-scrolling: touch;

  .modal-open--with-nav & {
    z-index: 999;
  }

  &.is-active {
    display: flex;
    opacity: 1;
    visibility: visible;
    overflow-x: hidden;
    overflow-y: auto;
  }

  // scss-lint:disable DeclarationOrder
  @include mq('lt-md') {
    padding-top: $gutter;

    &.is-active {
      display: block;
    }
  }

  // scss-lint:disable DeclarationOrder
}

// Anchor modal from the top of the screen instead of verticall centering
.c-modal--align-top:not(.c-modal--video) {
  align-items: flex-start;
}

.o-center-block{
  margin:0 auto;
}

.c-modal__content-container {
  position: relative;
  width: 90%;
  margin: 0 auto;
  // z-index: 301; // TODO: Find a way to keep the content above the nav to fix IE10 (because there's no pointer-events; maybe use polyfill?)

  // TODO: Use max-width uilities instead of defining across all modals
  @include mq('md') {
    // min-width: 500px;
    // max-width: 800px;
    // max-width: 405px;
    margin: 0;
  }
}

// TODO: Make sure this always has a dark background, otherwise need a way to change to dark when needed
.c-modal__close {
  right: auto;
  left: auto;
  top: -30px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  column-gap: 12px;
  width: auto;
  height: auto;
  z-index: 1005;
  position: absolute;
  @include cta--sm();
  color: $color-bone;

  &:focus {
    text-decoration: none;
  }

  &:focus-visible {
    outline: none;
    text-decoration: none;
    color: $color-bone;
    // text-decoration: underline;
  }

  &:hover, &:focus {
    color: $color-bone;
  }

}

.c-icon--close  {
  width: 11px;
  height: 11px;
  display: block;
  margin: 0;
}

.c-modal__close--white {
  background-image: svg(_buildSvg("<line x1='0' y1='0' x2='13' y2='13' stroke='#{$color-white-1}' stroke-width='2px'/><line x1='13' y1='0' x2='0' y2='13' stroke='#{$color-white-1}' stroke-width='2px'/>", 13, 13), 13, 13);
}

.c-modal__content {
  position: relative;
  min-height: 3em; // Just so close button always fits in content

  padding: 55px 20px 20px;

  background: $color-burnt-cacao;

  @include mq('lg') {
    padding: 55px 40px 40px;
  }
}





// --------------------
// Modifiers
// --------------------

.c-modal--video {
  padding: 1.5em 0;
  justify-content: center;
}

.c-modal--video .c-modal__content-container {

  // Account for close button positioning
  padding-top: 40px;

  // Keep 16x9 videos inside of the viewport
  @media (orientation: landscape) {
    $gutter-val: 96px;
    $gutter-div: 16px;

    $modal-padding: math.div($gutter-val, $gutter-div);
    $modal-content-container-padding: 10px;
    $modal-content-padding: 55px;
    $padding: $modal-padding + $modal-content-container-padding + $modal-content-padding;
    max-width: calc((100vh - #{$padding}) * math.div(16, 9));

    @include mq('lt-md') {
      $modal-padding: $gutter + (math.div(48, 16) * 16px);
      $modal-content-container-padding: 10px;
      $modal-content-padding: 55px;
      $padding-total: $modal-padding + $modal-content-container-padding + $modal-content-padding;
      max-width: calc((100vh - #{$padding-total}) * math.div(16, 9));
    }
  }
  @media (orientation: portrait) {
    @include mq('lt-md'){
      position: absolute;
      width: 90%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .o-video__media {
    aspect-ratio: 16/9;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .c-modal__content {
    padding: 0;
    background: transparent;
  }

}

#sweetbs-modal{

$font-family--minion-pro: minion-pro-display, 'times new roman', times, georgia, serif;
$font-family--display: myriad-pro, 'Arial', sans-serif;
-webkit-font-smoothing: antialiased;
.logo{
  width:15vw;
  min-width:150px;
}

.c-icon--close{
  border-radius: 50%;
  color: #fff;
  border: 2px solid white;
  display: block;
  height: 35px;
  width: 35px;
  padding: 10px;
}

h1{
  font-size: em(30);
  font-style: normal;
  font-weight: 400;
  line-height: 102%;
  font-family: 'minion-pro-display';
  color:#AB965D;

  .u-small-caps__sub{
    margin-right:0;
    padding-right: .4em;
    &:after{
      margin-bottom: 0.44em;
      transform: translateY(50%);
    }
  }
}
p.c-copy{
  font-family: 'minion-pro';
  font-size: em(21);
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: 0.742px;
  color:#FBF6EA;
}
.c-copy--xxs{
  opacity: 0.66;

  a{
   color:#FBF6EA;
  }
}

.c-button{
  font-family:'myriad-pro';
  span{
    padding: 11px 25px 12px 25px;
    font-size: 12px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 2px;
  text-transform: uppercase;
  }
}

}

// --
// Back To School
// --
$font-family--display-bts: 'myriad-pro', 'Arial', sans-serif;
.c-copy--bts{
  font-family:$font-family--display-bts;
}
picture {
    z-index: 0;
}

.middle-text {
    z-index: 99;
}

.circle {
    display: block;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    color: #FFF;

    &.red-bg {
        background-color: #ae1f19;
    }
    > .c-icon--close{
      top: calc(50% - 16px/2);
      left: calc(50% - 16px/2);
    }
}
.c-modal__content-container{
&.rainbow-border {
    border: 8px solid transparent;

    &:after {
        position: absolute;
        top: -8px;
        bottom: 0;
        left: -8px;
        right: -8px;
        background: rgba(0, 0, 0, 0) linear-gradient(-45deg, #57257d 0%, #57257d 23%, #57257d 23%, #ae1f19 23%, #ae1f19 39%, #123ca6 39%, #123ca6 39%, #123ca6 57%, #123ca6 57%, #187816 57%, #187816 79%, #57257d 79%, #57257d 88%, #ae1f19 88%);
        background-position: top left;
        background-size: contain;
        background-repeat: repeat-x;
        content: '';
        z-index: -1;

    }
}
}


.c-modal.hellofresh-modal {

  .c-modal__content-container {
    position: relative;
    background: #000;

    &::before {
      content: '';
      position: absolute;
      border: 2px solid $color-brand-red;
      top: 35px;
      right: 35px;
      bottom: 35px;
      left: 35px;
    }
  }

  .c-modal__content {
    background: transparent;
    padding: 50px;

    @include mq('md') {
      padding: 70px;
    }
  }

  .c-modal__close {
    top: 3em;
    right: 3em;
  }

}

body.modal-open.landing-hummus::after {
  opacity: 0.45;
}


@import './announcement-modal';
