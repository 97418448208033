@use "sass:math";

// TODO: Convert to `u-aspect-ratio` from mode-front-end
// @include u-aspect-ratio(4, 3);

// Defaults to square
.o-preserve-aspect-ratio {
  width: 100%;
  height: 0;
  padding: 0 0 100%;
}

.o-preserve-aspect-ratio\@lt-680 {
  @include mq('lt-680') {
  width: 100%;
  height: 0;
  padding: 0 0 100%;
  }
}

// TODO: Make a mixin to generate these
.o-preserve-aspect-ratio--1x1 {
  padding: 0 0 percentage(math.div(1, 1));
}

.o-preserve-aspect-ratio--71x106 {
  padding: 0 0 percentage(math.div(71, 106));
}
.o-preserve-aspect-ratio--69x52 {
  padding: 0 0 percentage(math.div(69, 52));
}

.o-preserve-aspect-ratio--5x12 {
  padding: 0 0 percentage(math.div(5, 12));
}

.o-preserve-aspect-ratio--25x42 {
  padding: 0 0 percentage(math.div(25, 42));
}

.o-preserve-aspect-ratio--5x12\@680 {
  @include mq('680') {
  padding: 0 0 percentage(math.div(5, 12));
  }
}

.o-preserve-aspect-ratio--217x188\@lt-680 {
  @include mq('lt-680') {
  padding: 0 0 percentage(math.div(217, 188));
  }
}

.o-preserve-aspect-ratio--360x375\@lt-680 {
  @include mq('lt-680') {
  padding: 0 0 percentage(math.div(360, 375));
  }
}

.o-preserve-aspect-ratio--51x29 {
  padding: 0 0 percentage(math.div(51, 29));
}

.o-preserve-aspect-ratio--51x29\@lt-680 {
  @include mq('lt-680') {
  padding: 0 0 percentage(math.div(51, 29));
  }
}


.o-preserve-aspect-ratio--51x29\@lt-md {
  @include mq('lt-md') {
  padding: 0 0 percentage(math.div(51, 29));
  }
}

.o-preserve-aspect-ratio--2x1 {
  padding: 0 0 percentage(math.div(1, 2));
}

.o-preserve-aspect-ratio--1x2 {
  padding: 0 0 percentage(math.div(2, 1));
}

.o-preserve-aspect-ratio--16x9 {
  padding: 0 0 percentage(math.div(9, 16));
}

.o-preserve-aspect-ratio--3x2 {
  padding: 0 0 percentage(math.div(2, 3));
}

.o-preserve-aspect-ratio--10x7 {
  padding: 0 0 percentage(math.div(7, 10));
}

.o-preserve-aspect-ratio--4x3 {
  padding: 0 0 percentage(math.div(3, 4));
}

.o-preserve-aspect-ratio--3x4 {
  padding: 0 0 percentage(math.div(4, 3));
}

.o-preserve-aspect-ratio--3x5 {
  padding: 0 0 percentage(math.div(5, 3));
}

.o-preserve-aspect-ratio--13x10 {
  padding: 0 0 percentage(math.div(13, 10));
}

.o-preserve-aspect-ratio--77x125 {
  padding: 0 0 percentage(math.div(77, 125));
}

.o-preserve-aspect-ratio--25x13\@md {
  @include mq('md') {
  padding: 0 0 percentage(math.div(13, 25));
}
}

.o-preserve-aspect-ratio--14x4\@md {
  @include mq('md') {
    padding: 0 0 percentage(math.div(4, 14));
  }
}

.o-preserve-aspect-ratio--3x2\@md  {
  @include mq('md') {
  padding: 0 0 percentage(math.div(2, 3));
  }
}

.o-preserve-aspect-ratio--17x5\@md {
  @include mq('md') {
    padding: 0 0 percentage(math.div(5, 17));
  }
}
.o-preserve-aspect-ratio--5x3\@680 {
  @include mq('680') {
    padding: 0 0 percentage(math.div(3, 5));
  }
}
.o-preserve-aspect-ratio--22x5\@1040 {
  @include mq('1040') {
    padding: 0 0 percentage(math.div(5, 22));
  }
}
.o-preserve-aspect-ratio--22x5\@680 {
  @include mq('680') {
    padding: 0 0 percentage(math.div(5, 22));
  }
}
.o-preserve-aspect-ratio--22x5\@md {
  @include mq('md') {
    padding: 0 0 percentage(math.div(5, 22));
  }
}
.o-preserve-aspect-ratio--24x7\@md {
  @include mq('md') {
    padding: 0 0 percentage(math.div(7, 24));
  }
}
.o-preserve-aspect-ratio--5x3 {
  padding: 0 0 percentage(math.div(3, 5));
}
.o-preserve-aspect-ratio--1600x733 {
  padding: 0 0 percentage(math.div(733, 1600));
}
// Everroast Hero
.o-preserve-aspect-ratio--40x19\@md {
  @include mq('md') {
    padding: 0 0 percentage(math.div(19, 40)) !important;
  }
}

.o-preserve-aspect-ratio--16x7\@md {
  @include mq('md') {
    padding: 0 0 percentage(math.div(7, 16)) !important;
  }
}

.o-preserve-aspect-ratio--16x8\@md {
  @include mq('md') {
    padding: 0 0 percentage(math.div(8, 16)) !important;
  }
}
.o-preserve-aspect-ratio--4x3\@md {
  @include mq('md') {
    padding: 0 0 percentage(math.div(3, 4));
  }
}
.o-preserve-aspect-ratio--3x1\@1040 {
  @include mq('1040') {
    padding: 0 0 percentage(math.div(1, 3));
  }
}
.o-preserve-aspect-ratio--3x1\@lg {
  @include mq('lg') {
    padding: 0 0 percentage(math.div(1, 3));
  }
}

.o-preserve-aspect-ratio--3x1\@md {
  @include mq('md') {
    padding: 0 0 percentage(math.div(1, 3));
  }
}
.o-preserve-aspect-ratio--89x116 {
  padding: 0 0 percentage(math.div(116, 89));
}


// Our Story page
.o-preserve-aspect-ratio--552x432 {
  padding: 0 0 percentage(math.div(432, 552));
}

// Nutrition and Wellness page
.o-preserve-aspect-ratio--629x449 {
  padding: 0 0 percentage(math.div(449, 629));
}

.o-preserve-aspect-ratio--440x593 {
  padding: 0 0 percentage(math.div(593, 650));
}

// Holiday Giving
.o-preserve-aspect-ratio--1200x700 {
  padding: 0 0 percentage(math.div(700, 1200));
}

.o-preserve-aspect-ratio--500x370 {
  padding: 0 0 percentage(math.div(370, 500));
}

.o-preserve-aspect-ratio--245x370 {
  padding: 0 0 percentage(math.div(370, 245));
}

.o-preserve-aspect-ratio--419x632 {
  padding: 0 0 percentage(math.div(632, 419));
}


.o-preserve-aspect-ratio--339x406 {
  padding: 0 0 percentage(math.div(406, 339));
}

// 2017 Platinum
.o-preserve-aspect-ratio--20x9\@lg {
  @include mq('lg') {
    padding: 0 0 percentage(math.div(9, 20));
  }
}

.o-preserve-aspect-ratio--35x33\@lg {
  @include mq('lg') {
    padding: 0 0 percentage(math.div(33, 35));
  }
}

// 2018 Bold

.o-preserve-aspect-ratio--9x4 {
  padding: 0 0 percentage(math.div(4, 9));
}

.o-preserve-aspect-ratio--9x4 {
  padding: 0 0 percentage(math.div(4, 9));
}

// Counter Culture
.cc-preserve-aspect-ratio--rule-one {
  display: none;
  // padding: 0 0 percentage(math.div(700, 375));
  // @include mq('680'){
  //   padding: 0 0 percentage(math.div(560, 768));
  // }
  @include mq('xl') {
    display: block;
    padding: 0 0 percentage(math.div(14, 25));
  }
  @include mq('1300') {
    display: block;
    padding: 0 0 percentage(math.div(860, 1680));
  }
  @include mq('1500') {
    display: block;
    padding: 0 0 percentage(math.div(11, 25));
  }
}

.cc-preserve-aspect-ratio--video {
  padding: 0 0 percentage(math.div(721, 375));
  @include mq('680') {
    padding: 0 0 percentage(math.div(900, 768));
  }

  @include mq('1024') {
    padding: 0 0 percentage(math.div(9, 16));
  }
}

.cc-preserve-aspect-ratio--header {
  padding: 0 0 percentage(math.div(768, 375));
  @include mq('680') {
    padding: 0 0 percentage(math.div(900, 768));
  }

  @include mq('1024') {
    padding: 0 0 percentage(math.div(9, 16));
  }
}

// ------------------------------
// Responsive
// ------------------------------

.o-preserve-aspect-ratio--3x4\@680 {
  @include mq('680') {
    padding: 0 0 percentage(math.div(4, 3));
  }
}

.o-preserve-aspect-ratio--16x9\@680 {
  @include mq('680') {
    padding: 0 0 percentage(math.div(9, 16));
  }
}

.o-preserve-aspect-ratio--16x9\@md {
  @include mq('md') {
    padding: 0 0 percentage(math.div(9, 16));
  }
}

.o-preserve-aspect-ratio--16x9\@1024 {
  @include mq('1024') {
    padding: 0 0 percentage(math.div(9, 16));
  }
}

.o-preserve-aspect-ratio--16x9\@1040 {
  @include mq('1040') {
    padding: 0 0 percentage(math.div(9, 16));
  }
}

// TODO: Make `btwn` alias for mq
.o-preserve-aspect-ratio--3x2\@btwn-sm-and-md {
  @media (min-width: map-get($breakpoints, 'sm')) and (max-width: map-get($breakpoints, 'md') - 0.0625em) {
    padding: 0 0 percentage(math.div(2, 3));
  }
}

.o-preserve-aspect-ratio--18x10\@md {
  @include mq('md') {
    padding: 0 0 percentage(math.div(10, 18));
  }
}

.o-preserve-aspect-ratio--3x1\@1024 {
  @include mq('1024') {
    padding: 0 0 percentage(math.div(1, 3));
  }
}

.o-preserve-aspect-ratio--147x16\@1040 {
  @include mq('1040') {
    padding: 0 0 percentage(math.div(16, 147));
  }
}

.o-preserve-aspect-ratio--18x10\@1040 {
  @include mq('1040') {
    padding: 0 0 percentage(math.div(10, 18));
  }
}

.o-preserve-aspect-ratio--covid19 {
  padding: 0 0 percentage(math.div(1260, 2400));
}

// Bold 2020


.o-preserve-aspect-ratio--pitcraft-craftsmanship-smoker {
  padding: 0 0 percentage(math.div(815, 375));

  @include mq('680') {
    padding: 0 0 percentage(math.div(680, 680));
  }

  @include mq('md') {
    padding: 0 0 percentage(math.div(1680, 1520));
  }
}

.o-preserve-aspect-ratio--pitcraft-header-video {
  padding: 0 0 percentage(math.div(1080,1920));
}

.o-preserve-aspect-ratio--pitcraft-activity-state {
  padding: 0 0 percentage(math.div(350,750));
  @include mq('md') {
    padding: 0 0 percentage(math.div(450,1680));
  }
}

.o-preserve-aspect-ratio--pitcraft-success-state {
  padding: 0 0 percentage(math.div(230,375));

  @include mq('680') {
    padding: 0 0 percentage(math.div(498,1680));
  }
}

.o-preserve-aspect-ratio--pitcraft-video-activity {
  padding: 0 0 percentage(math.div(520,345));
  @include mq('680') {
    padding: 0 0 percentage(math.div(800,1200));
  }
}

.o-preserve-aspect-ratio--pitcraft-recipe-activity {
  padding: 0 0 percentage(math.div(520,345));

  @include mq('680') {
    padding: 0 0 percentage(math.div(697,1320));
  }
}

.o-preserve-aspect-ratio--pitcraft-recipe-image {
  padding: 0 0 percentage(math.div(260,271));

  @include mq('680') {
    padding: 0 0 percentage(math.div(700,660));
  }
}

.o-preserve-aspect-ratio--pitcraft-mobile-form {
  padding: 0 0 percentage(math.div(260,271));
}

// TODO: Make a mixin to generate these
.o-preserve-aspect-ratio--1x1\@680 {
  @include mq('680') {
  padding: 0 0 percentage(math.div(1, 1));
  }
}

.o-preserve-aspect-ratio--1x1\@lg {
  @include mq('lg') {
  padding: 0 0 percentage(math.div(1, 1));
  }
}

.o-preserve-aspect-ratio--pitcraft-smokey-meat {

  padding: 0 0 percentage(math.div(150,320));

  @include mq('360') {
    padding: 0 0 percentage(math.div(215,375));
  }

  // iPhone X
  @media (min-height: 812px) and (max-width: 375px) {
    padding: 0 0 percentage(math.div(266,375));
  }

  // iPhone 11
  @media (max-width: 414px) and (min-height: 896px) {
    padding: 0 0 percentage(math.div(266,375));
  }

  @media (orientation: portrait) and (min-width: 1024px) {
    padding: 0 0 percentage(math.div(266,375));
  }
}

.o-preserve-aspect-ratio--pitcraft-success-mobile-meat {
  padding: 0 0 60%;
}

.o-preserve-aspect-ratio--pitcraft-success-meat {
  padding: 0 0 percentage(math.div(1798,2350));
}

.o-preserve-aspect-ratio--pitcraft-apron {
  padding: 0 0 percentage(math.div(503,375));
  @include mq('680') {
    padding: 0 0 percentage(math.div(928,1021));
  }

  @media (orientation: portrait) and (min-width: 680px) {
    padding: 0 0 percentage(math.div(650,768));
  }

  @include mq('md') {
    padding: 0 0 percentage(math.div(561,768));
  }

  @media (orientation: portrait) and (min-device-width: 1024px) and (max-device-width: 1024px) {
    padding: 0 0 66%;
  }

  @media (min-device-width: 768px)
    and (max-device-width: 1024px)
    and (orientation:landscape) {
    padding: 0 0 62.5%;
  }
  @include mq('1040') {
    padding: 0 0 percentage(math.div(25,31));
  }
  @include mq('1300') {
    padding: 0 0 percentage(math.div(181,288));
  }
}

.o-preserve-aspect-ratio--pitcraft-bonus-entry {
  padding: 0 0 percentage(math.div(146,472));
}
