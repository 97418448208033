@include u-object-fit('cover-100%', cover) {
  width: 100%;
  height: 100%;
}

@include u-object-fit('cover-100%', cover, 'lt-1024') {
  width: 100%;
  height: 100%;
}

@include u-object-fit('cover-100%', cover, 'lt-1040') {
  width: 100%;
  height: 100%;
}

// ------------------------------
// Responsive
// ------------------------------

// Max-width
@include u-object-fit('cover-100%', cover, 'lt-xl') {
  width: 100%;
  height: 100%;
}

// Min-width
@include u-object-fit('cover-100%', cover, 'md') {
  width: 100%;
  height: 100%;
}
@include u-object-fit('cover-100%', cover, 'lt-1040') {
  width: 100%;
  height: 100%;
}
