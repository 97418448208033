.c-product-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(-2%);
  transition:
    transform 200ms ease-in-out,
    opacity 400ms ease-out;
  opacity: 0;
  z-index: 1;
  -webkit-backface-visibility: hidden;
}
// HACK: Keep image invisible on lazyload
.c-product-label.lazyloaded {
  opacity: 0;
}

.c-link {

  .c-product-label {
    display: none;
  }

  @include mq('lg') {

    .c-product-label {
      display: block;
    }

    @include hover {
      .c-product-label {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}
