.c-form__input {

  text-decoration: none;
  padding-left: 16px;
  padding-right: 16px;
  background: transparent;
  width: 100%;
  height: var(--input-height);
  color: var(--input-text-color);
  border: none;
  outline: 1px solid var(--input-outline-color);
  @include cta--sm();

  &::placeholder {
    color: var(--input-text-color);
    font-style: normal;
  }

  &:focus, :active {
    --input-outline-color: var(--input-outline-focus-color);
  }

  .c-form__field-wrapper.is-invalid & {
    --input-outline-color: var(--input-error-color);
  }
}

.c-form__input--textarea {
  height: auto;
  background: transparent;
  border:0px;

  &::placeholder {
    color: $color-bone;
    @include cta--sm();
  }
}

.c-form__input--select {

  position: relative;

  select {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 2em 0 0;
    margin: 0;
    width: auto;
    height: 100%;
    cursor: inherit;

    color: $color-bone;
    @include cta--lg();

    &::placeholder{
      color: $color-bone;
      @include cta--lg();
    }

    &:focus, :active {
      outline: 0;
    }
  }

  opacity: 0;
  transition: opacity .1s;

  &.select-sized {
    opacity: 1;
  }

  .select-arrow {
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 20px;
    margin-right: 15px;
    pointer-events: none;
  }
}



.select-size-helper {
  @include cta--lg();
  padding-right: 2em; // account for arrow
}
