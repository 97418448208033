body {
  opacity: 1;
}

// MODE Front-end
@import '../../../node_modules/mode-front-end/resources/assets/sass/common';

@import 'app/base/common-redesign';

// // Vendor
@import 'app/vendor/flickity';
@import '../../../node_modules/swiper/swiper';
@import '../../../node_modules/swiper/modules/navigation';

@import 'app/objects/list';
@import 'app/objects/preserve-aspect-ratio';
@import 'app/objects/sidebar-layout';
@import 'app/objects/video/video';


@import 'app/components/active-list';
@import 'app/components/cursors';
@import 'app/components/link';
@import 'app/components/button';
@import 'app/components/footer';
@import 'app/components/form';
@import 'app/components/brochure';
@import 'app/components/tabs';
@import 'app/components/section-two-column';
@import 'app/components/feature-cards';
@import 'app/components/footnotes';
@import 'app/components/heading-plate';
@import 'app/components/product-card';
@import 'app/components/recipe-card';

@import 'app/components/full-cta';
@import 'app/components/breadcrumbs';
@import 'app/components/dropdown';

@import 'app/components/section-half-half';
@import 'app/components/section-twocol-asset';
@import 'app/components/section-full-asset';
@import 'app/components/modals';
@import 'app/components/typography/copy';

@import 'app/components/share-bar';
@import 'app/components/ingredients-list';
@import 'app/components/nutrition';
@import 'app/components/product-label';
@import 'app/components/social-nav';
@import 'app/components/accordion';
@import 'app/components/share-services';

@import 'app/utilities/max-width';
@import 'app/utilities/object-fit';
@import 'app/utilities/position';

@import 'app/views/nutrition-and-wellness';

@import 'app/views/search';
@import 'app/views/resources/privacy';
@import 'app/views/readyadditions';
// @import 'app/views/servicecase';
@import 'app/views/posts';

@import 'app/views/modals/newsletter';
@import 'app/views/modals/email-share-modal';

// @import 'app/views/element/product-stage';
@import 'app/views/element/career-footer';
@import 'app/views/element/locations-dishworthy';
@import 'app/views/element/large-recipe';
@import 'app/views/element/x-up-recipe';
@import 'app/views/element/next-post';
@import 'app/views/search';
@import 'app/views/dynamic-pages';

// // ADA
// @import 'app/base/ada';

@import 'app/views/modals/purveyors-modal';

// Print
@import 'app/base/print';

// Hacks
// @import 'app/hacks/index';

// .anchor-offset {
//   position: absolute;
//   width: 100%;
//   top: -100px;
// }
