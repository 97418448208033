
.heading-plate {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 28px;

  @include mq($desktop-bp) {
    height: 36px;
  }

  &::before {
    content: '';
    width: 7.7777778px;
    height: 100%;
    background-image: url(cache-ts-url('/img/pinnacle/backgrounds/plate-left-cap.webp'));
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: -1px;

    @include mq($desktop-bp) {
      width: 10px;
    }
  }

  &::after {
    content: '';
    width: 7.7777778px;
    height: 100%;
    background-image: url(cache-ts-url('/img/pinnacle/backgrounds/plate-right-cap.webp'));
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: -1px;

    @include mq($desktop-bp) {
      width: 10px;
    }
  }
}

.heading-plate__content {
  background-image: url(cache-ts-url('/img/pinnacle/backgrounds/plate.webp'));
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;

  @include heading--sm();
  color: #000;

  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;

  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: .01em;

  @include mq($desktop-bp) {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: .1em;
  }
}

.heading-plate--short {
  .heading-plate__content {
    background-image: url(cache-ts-url('/img/pinnacle/backgrounds/plate-short.webp'));
  }

  &::before {
    background-image: url(cache-ts-url('/img/pinnacle/backgrounds/plate-left-cap-short.webp'));
    background-size: contain;
    margin-right: -2px;
  }

  &::after {
    background-image: url(cache-ts-url('/img/pinnacle/backgrounds/plate-right-cap-short.webp'));
    background-size: contain;
  }
}

.heading-plate--img {
  &::before, &::after {
    display: none;
  }
}

.heading-plate--craft {
  width: 86px;
  @include mq('lg') {
    width: scale-1200(101.926px);
  }
}

.heading-plate--featured {
  width: 91px;
  @include mq('lg') {
    width: scale-1200(123.303px);
  }
}

.heading-plate--flavor {
  width: 86.637px;
  @include mq('lg') {
    width: scale-1200(101.926px);
  }
}

.heading-plate--freshness {
  width: 113.893px;
  @include mq('lg') {
    width: scale-1200(133.992px);
  }
}

.heading-plate--new {
  width: 86px;
  @include mq('lg') {
    width: scale-1200(101.926px);
  }
}
