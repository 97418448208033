$content-max-width: 1410px;

$page-margins--mobile: 25px;
$page-margins--outer-tablet: 35px;
$page-margins--outer: 80px;
$page-margins--inner-tablet: 60px;
$page-margins--inner: 135px;

$navigation-margins--mobile: 25px;
$navigation-margins--outer: 80px;
$navigation-margins--inner: 116px;

$main-header-margins--mobile: $page-margins--mobile;
$main-header-margins: $page-margins--outer;

$spacing-card-padding: 30px;

// Minimums
$spacing-section-vertical-min: 60px;
$spacing-section-horizontal-min: 30px;

$spacing-element-horizontal-min: 40px;
$spacing-element-vertical-min: 40px;
$section-header--mobile: 52px;

$gutter-xxs: em(10);
$gutter-xs: em(20);
$gutter-sm: em(30);
$gutter-md: em(48); // Page margins
$gutter-lg: em(64);
$gutter-xl: em(80);
$gutter-xxl: em(170);
