@use "sass:math";

// Note: Uses `.u-small-caps`
.c-button--circle {
  $size: em(80, 18);
  $size-hover: em(94, 18);
  @include circle($size, $size-hover);
  @include circle-hover($size, $size-hover);
  @include font-button;
  // scss-lint:disable PropertySortOrder
  position: relative;
  width: em(94, 18);
  height: em(94, 18);
  color: $color-brand-gold;
  line-height: math.div(15, 18);

  // HACK: Reset flexbox, because it throws off centering in Safari
  // display: inline-block;
  // UPDATE: `inline-flex` throws off vertical centering of anchors in Chrome (haven't tested elsewhere), but not for buttons.
  display: flex;
  text-decoration: none;
  text-align: center;

  // TODO: Scale up all circles on mobile
  // @include mq($bp-shrink-fonts) {
  //   transform: translate(-50%, -50%) scale(#{ (math.div(16,13)) });
  // }
}

.c-button--circle-icon {
  background: var(--btn-circle-icon-bg);
  color: var(--btn-circle-icon-color);

  width: fluid(55px, 75px);
  height: fluid(55px, 75px);

  border-radius: 50%;

}
