$desktop-bp: 'lg';

.locations-dishworthy {

  --locations-dishworthy-spacing-top: 55px;
  --locations-dishworthy-spacing-bottom: 48px;
  --locations-dishworthy-spacing-right: var(--page-margins-outer);
  --locations-dishworthy-spacing-left: var(--page-margins-outer);

  @include mq($desktop-bp) {
    --locations-dishworthy-spacing-top: #{scale-1200(76px)};
    --locations-dishworthy-spacing-bottom: #{scale-1200(93px)};
  }

  padding: var(--locations-dishworthy-spacing-top) var(--locations-dishworthy-spacing-right) var(--locations-dishworthy-spacing-bottom);

  background-image: url(cache-ts-url('/img/pinnacle/global/bg-brick-mobile.webp'));
  background-repeat: no-repeat;
  background-size: cover;

  @include mq($desktop-bp) {
    background-image: url(cache-ts-url('/img/pinnacle/global/bg-brick-desktop.webp'));
    grid-template-columns: 1fr 1fr;
  }
}

.locations-dishworthy__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > .heading {
    text-align: center;
  }

  .c-icon--filigree {
    margin-bottom: 19px;
    @include mq($desktop-bp) {
      margin-bottom: scale-1200(25px);
    }
  }

  h2 {
    @include heading--md();
    margin-bottom: 23px;

    @include mq($desktop-bp) {
      margin-bottom: scale-1200(25px);
    }
  }

  > p {
    text-align: center;
    @include body--xl();

    margin-bottom: 32px;

    @include mq($desktop-bp) {
      margin-bottom: scale-1200(40px);
    }
  }
}

.locations-dishworthy__links {
  display: flex;
  column-gap: scale-1200(22px);
  row-gap: 14px;
  justify-content: center;

  flex-direction: column;

  @include mq('lg') {
    flex-direction: row;
  }

}

.locations-dishworthy__link {
  border-image: url(cache-ts-url('/img/pinnacle/global/frame-border.webp')) 28 stretch stretch;
  border-image-width: 18px;
  border: 0px;
  border-style: inset;

  padding: 29px;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  position: relative;

  width: scale-1200(370px);
  height: scale-1200(128px);

  @include mq('680')  {
    gap: scale-1200(10px);
  }

  .heading {
    @include heading--md();
    text-align: center;
    margin-bottom: 8px;
  }

  > span:not(.heading) {
    @include cta--lg();
    color: var(--color-bone);
    text-align: center;
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .c-icon {
    width: scale-1200(12px);
    height: scale-1200(10px);
  }

  text-decoration: none;

  .btn.btn--secondary  {
    align-self: center;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &:hover, &:focus {
    > span:not(.heading) {
      text-decoration: underline;
    }
  }
}



