@use "sass:math";

// scss-lint:disable DeclarationOrder

#nutrition-and-wellness {
  background-image: url(cache-ts-url('/img/pinnacle/backgrounds/mobile-bottom-nutrition.webp'));
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;

  @include mq('md'){
    background-image: url(cache-ts-url('/img/pinnacle/backgrounds/page-bg-map.webp'));
  }
}

.nutrition-wellness {
  scroll-behavior: smooth;
  main{
    background-image: url(cache-ts-url('/img/pinnacle/backgrounds/mobile-dark-map-short.webp'));
    background-repeat: no-repeat;
    background-position: right -10px;
    background-size: 100vw;
    scroll-behavior: smooth;


    @include mq('md'){

      background-image: url(cache-ts-url('/img/pinnacle/backgrounds/map-top-dk.webp'));

    }

  }

  #m-wrapper {
    padding-top: var(--header-height);
  }

  .breadcrumbs {
    position: absolute;
    top: var(--header-full-height);
  }

  .half-half__content{
    .section__copy{
      margin-bottom:scale-1200(50px);
    }

    .c-icon.c-icon--health {
      svg {
        stroke: none;
        fill: none;
      }
    }

    .section__ctas {
      .btn {
        position: relative;
        z-index: 1;
      }
    }
  }

  .section-header {
    picture {
      img {
        font-family: "object-fit: cover;";
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
      }
    }

    &.opacity {
      .section-header__background {
        opacity: 0.3;

        @include mq('lg') {
          opacity: 0.4;
        }
      }
    }

    .section-header__content {
      max-width: 682px;
      width: 100%;
    }
  }

  .section-header--primary-short {
    .section-header__disclaimer {
      @include mq('lg') {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  @include mq('lt-lg') {
    .half-half__image{
      .half-half__background{
        width: 75%;
        margin: 0 auto;
      }
    }
 }

 .half-half__background{

  @include mq('md') {
  background:linear-gradient(86deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.36458333333333337) 10%, rgba(0,0,0,1) 42%, rgba(0,0,0,1) 57%, rgba(0,0,0,0) 100%)
  }
 }

  .section-header:not(.section-header--primary-short):not(.section--background) {
    .section-header__content {
      max-width: 870px;
    }
  }

  .section--background {
    .section-header__content {

      max-width: 682px;
      width: 100%;
    }

  }

  .section-header__heading {

    @include reset-small-caps();
    @include mq('870'){
      &:last-child {
        padding-bottom: scale-1200(70px);
      }
    }
  }

  .padding {
    padding: scale-1200(78px) 0;
  }

  .quality-promise {
    padding-top: 70px;

    @include mq('lg') {
      padding-top: scale-1200(125px);
    }

    img {
      padding-bottom: scale-1200(43px);
    }

    .section-header__content {
      margin-top: 0;
    }
  }

  .product-section{
    @include mq('lt-870'){
      padding-top: scale-vw-mobile(34px);
    }

  }

  .nutrition-list--section {
    padding-left: var(--page-margins-inner);
    padding-right: var(--page-margins-inner);
    text-align: center;
    padding-bottom: scale-vw-mobile(142.5px);

    @include mq('870') {
      padding-bottom: scale-1200(90px);
    }

    h2 {
      @include heading--lg();
      padding-top: scale-1200(120px);
      padding-bottom: scale-1200(86px);
    }

    .nutrition-list--container {
      display: grid;
      grid-template-columns: 1fr;

      row-gap: scale-vw-mobile(62px);

      @include mq('870') {
        grid-template-columns: 1fr 1fr;
        column-gap: scale-1200(22px);
        row-gap: scale-1200(63px);
      }

      a.nutrition-list {
        text-align: left;
        overflow:hidden;
        img {
          width: 100%;
        }

        .c-copy {
          text-align: left;
        }

        .c-icon {
          transform: translate(-1px, 1px);
          background-color:#151310;
        }
      }

      .nutrition-list__item {
        text-decoration: none;

        color: $color-bone;

        .img-wrap {
          position: relative;
          overflow: hidden;
          clip-path: polygon(0 0,99% 0,100% 100%,0 100%);
          img {
            width: 100%;
          }

          .c-icon {
            z-index: 1;
            background-color:#151310;
          }

          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.1);
            opacity: 0;
            z-index: 2;
            transition: opacity .2s ease-out;
          }

          img {
            transition: transform .2s ease-out;
          }
        }

        &:hover {
          .img-wrap {
            img {
              transform: scale(1.1);
            }

            &::after {
              opacity: 1;
            }
          }
        }

        h3 {
          padding-top: scale-1200(33px);
          padding-bottom: scale-1200(18px);
          color: $color-bone;
          text-align: left;
        }

        p {
          @include body--nutrition-list();
          max-width: 440px;
          color: $color-bone;
          text-align: left;
        }
      }
    }
  }

  .section-half-half {
    padding: 0 var(--page-margins-inner);
  }


.section-half-hero {

  // background-image: url(#{cache-ts-url('/img/pinnacle/backgrounds/map.webp')});
  // background-repeat: no-repeat;
  // background-position: right -3%;
  // background-size: contain;

  .page-navigation__top {
    justify-content: center;
    @include mq('870') {
      justify-content: space-between;
    }
  }

  main {
    padding-top: var(--header-height);
  }
}

.section-half-hero--full-hero {
  main {
    padding-top: var(--header-height);
    @include mq('1040') {
      padding-top: 0;
    }
  }
}

.section-half-hero__hero {
  display: grid;

  grid-template-columns: 100%;

  padding-top: 50px;

  .section-header__heading{
    text-align:center;
    width:90%;
    }

  @include mq('870') {
    grid-template-columns: 1fr 1fr;

    background-image: url(cache-ts-url('/img/pinnacle/backgrounds/map.webp'));
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: left 50%;

    padding-top: 0;

    margin-bottom:scale-1200(142px);

    .section-header__heading{
      text-align:left;
      width:auto;
    }
  }


}

.section-half-hero__hero-content {
  padding-left: var(--page-margins-inner);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: scale-1200(38px);

  position: relative;
  z-index: 1;

  &::after {
    content: '';
    width: 100%;
    height: 100px;
    background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(255,255,255,0) 100%);
    position: absolute;
    left: 0;
    bottom: -75px;
  }

  @include mq('870') {
    align-items: flex-start;

    &::after {
      display: none;
    }
  }

  img, .c-icon--health {
    margin-bottom: scale-1200(36px);
    width: 64px;
    height: 64px;

    svg {
      fill: none;
      stroke: none;
    }
  }

  .section-header__copy{
    @include mq('870') {
      text-align: left;
      padding-right:scale-1200(45px);
    }
  }
}


.section-half-hero__breadcrumb {
  @include heading--md();
  margin-bottom: scale-1200(30px);
}

.section-half-hero__title {
  @include heading--lg();
  margin-bottom: scale-1200(30px);
  text-align: center;

  @include mq('870') {
    text-align: left;
  }
}

.section-half-hero__description {
  margin-bottom: scale-1200(40px);
  > * {
    text-align: center;

    @include mq('870') {
      text-align: left;
    }
  }
}



.section-half-hero__ctas {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  column-gap: scale-1200(27px);
  position: relative;
  z-index: 1;
}

.section-half-hero__hero-media {
  width: 100%;
  // height: 100%;
  // overflow: hidden;

  aspect-ratio: 820 / 720;

  img, video {
    width: 100%;
    height: 100%;
    object-fit: cover;

    z-index: 3;
    position: relative;
  }
}

.section-half-hero__hero--full {
  .section-half-hero__hero-media {

    aspect-ratio: 750 / 940;

    grid-column: 1;
    grid-row: 1 / span 2;

    margin-top: 154px;

    @include mq('680') {
      margin-top: 0px;
    }

    @include mq('870') {
      aspect-ratio: 1440 / 820;
      grid-column: 1 / span 2;
      grid-row: 1;
    }
  }

  .section-half-hero__hero-content {
    grid-column: 1;
    grid-row: 1;
    position: relative;
    z-index: 1;

    &::after {
      display: none;
    }
  }

}

// .btn--primary-line{
//   .btn__content{
//     &:after{
//       z-index:0;
//     }
//   }
// }

.section-half-hero__image--mobile {
  display: block;

  @include mq('870') {
    display: none;
  }
}

.section-half-hero__image--desktop {
  display: none;

  @include mq('870') {
    display: block;
  }
}


.product-listing__content{
  display: flex;
  flex-direction: column;
  .btn {
    display:inline-flex;
    align-self:center;
    margin-top:scale-1200(70px);
  }
}

.product-listing__cta {
  display: flex;
  justify-content: center;
  margin-bottom: 90px;
  @include mq('680') {
    margin-bottom: scale-1200(123px);
  }
}

.faq-scroll{
  padding-top: 200px;
    margin-top: -200px;
}
}

.half-half__left--brochures {

  &.section-half-half {
    @include mq('lg') {
      grid-template-columns: 1fr 40%;
    }
  }

  .half-half__content {
    max-width: none;
  }

  .half-half__background {
    img {
      max-width: scale-1200(385px);
    }
  }
}


// ------------------------------
// Nutrition & Wellness Ornaments
// ------------------------------

/**
 * TODO: Get with designers about responsive adjustments for these figures.
 * Definitely needs help between 450px and 850px.
 */
// $content-width : 1200px;
// $figure-width  : math.div($content-width, 2);

// .c-nutrition-and-wellness__figure {
//   $section-height: 360px;
//   z-index: -1;

//   img {
//     position: absolute;
//     margin-left: auto;
//     top: 0;
//   }
// }

// .c-nutrition-and-wellness__figure--ingredients {
//   $img-width: 629px;
//   $img-height: 449px;
//   img {
//     width: percentage(math.div($img-width, $figure-width));
//     right: 0;
//   }
// }

// .c-nutrition-and-wellness__figure--nutrition {
//   $img-width: 440px;
//   $img-height: 593px;
//   img {
//     width: percentage(math.div($img-width, $figure-width));
//     right: 0;
//   }
// }



// // ------------------------------
// // Health Stamps
// // ------------------------------

// ._nutrition-icons {
//   max-width: em(680);

//   @include mq('lt-sm') {
//     font-size: em(12);
//   }

//   a {
//     line-height: math.div(17, 16);
//   }
// }
