.sidebar-layout--template {

  #m-wrapper {
    padding: 0;
  }

  main {
    background-image: url(cache-ts-url('/img/pinnacle/backgrounds/mobile-dark-map-short.webp'));
    background-repeat: no-repeat;
    background-position: right -10px;
    background-size: 100vw;
    scroll-behavior: smooth;

    @include mq('md') {
      background-image: url(cache-ts-url('/img/pinnacle/backgrounds/map-top-dk.webp'));
    }
  }

  .section-header {

    margin-bottom: 70px;
    @include mq('lg') {
      margin-bottom: scale-1200(100px);
    }

    picture {
      img{
        font-family: "object-fit: cover;";
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  .o-section {
    .breadcrumbs {
      padding-left:0;
    }

    padding-right: var(--page-margins-outer);
    padding-bottom: scale-1200(100px);
    padding-left: var(--page-margins-outer);

    padding-top: var(--header-full-height);

    .o-sidebar-layout {
      align-items: start;
      display: grid;
      grid-template-columns: 1fr;
      text-align: center;

      padding-top: 78px;

      @include mq('lg') {
        text-align: left;
        grid-template-columns: 4fr 2fr;
        padding-top: scale-1200(78px);
      }

      .o-sidebar-layout__content {

        padding-bottom: 70px;

        @include mq('lg') {
          padding-right: var(--page-margins-outer);
          padding-bottom: scale-1200(100px);
        }

        .content-header {

          @include mq('lg') {
            grid-column: span 2;
          }

          h2 {
            @include heading--lg();
            color: $color-brand-gold;
            margin-bottom:scale-1200(10px);
          }

          p {
            @include body--lg();
            line-height: 1.4em !important;
          }
        }

        .section-header__content {
          // margin: 6vw auto;
          // @include mq('lg') {
          //   margin:0 auto;
          // }
        }

        .copy--wrapper {

          text-align: left;

          p{
            margin-bottom:scale-1200(22px);
          }

          ol {
            color: $color-bone;
            margin-top:scale-vw-mobile(20px);

            li {
              margin-bottom:scale-1200(25px);
              text-align:left;
            }
          }
        }

      }

      .o-sidebar-layout__sidebar {

        text-align: left;
        align-self: start;

        top: calc(var(--header-full-height) + #{scale-1200(20px)});

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 5px;

        @include mq('lg') {
          position: sticky;
        }

        @include mq('xl') {
          padding-left: var(--page-margins-outer);
        }

        .o-sidebar-layout__sidebar-block {
          @include mq('lg') {
            grid-column: span 2;
          }

          h2 {
            color: $color-brand-gold;
            @include heading--sm();
            margin-bottom: scale-1200(25px);
          }

          p {
            @include body--lg();
            line-height: 1.4em !important;
            margin-bottom: scale-1200(10px);
          }

          a {
            @include cta--lg();
            color: $color-brand-gold;
          }
        }
      }
    }

    .hiring--grid{
      display:grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: scale-1200(20px);
      grid-row-gap: scale-1200(20px);
      align-items:center;
      justify-items: center;
      margin:scale-1200(50px);
      li{
        padding:20px;

        .o-list__item-inner{
          text-align:center;
          h3{
            @include heading--sm();
          }
          a{
            @include cta--lg();
          }
        }
      }
    }
  }
}
