// requires position relative on the "area" element.
@mixin full-area-cta() {
  &::after {
    content: '';
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

  }
}
