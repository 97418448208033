.c-ingredients-list {
  display: grid;
  grid-template-columns: minmax(100px, min-content) 1fr;
  grid-template-areas: "amount ingredient";
  grid-template-rows: auto;
  row-gap: scale-1200(24px);
  column-gap: scale-1200(20px);
}

// .c-ingredients-list__item {
//   @include clearfix;
//   display: flex;
//   position: relative;
//   align-items: center;
//   margin-bottom: 7px;
// }

.c-ingredients-list__amount {

  grid-area: amount;
  grid-row: auto;

  > span {
    display: inline-flex;
    width: 100%;
    padding: em(5) em(8);
    @include sans-serif--lg();
    background: #1E1D1A;
    color: var(--color-brand-gold);
    text-align: center;
    height: 49px;
    align-items: center;
    justify-content: center;
  }
}

.c-ingredients-list__ingredient {

  grid-area: ingredient;
  grid-row: auto;

  display: flex;
  align-items: flex-start;

  padding-top: .6em;

  @include mq('680') {
    padding-top: .4em;
  }

  > div {
    > div,
    > a {
      display: inline;

      &:not(:last-child)::after {
        display: inline;
        content: ',';
      }
    }
  }

  @include body--lg();

  a {
    color: var(--color-bone);

    @include hover {
      color: var(--color-bone);
    }
  }
}

.ingredients-list--wrap {
  display: flex;
  flex-wrap:wrap;

  row-gap: 30px;

  @include mq('lg') {
    row-gap: scale-1200(30px);
    column-gap: scale-1200(15px);
  }

  .c-heading {
    margin-bottom: scale-1200(30px);
  }
}

.ingredients-list--wrap.ingredients-list--wrap-multiple {

  flex-direction: column;

  @include mq('xl') {
    flex-direction: row;
    .o-grid__item {
      flex: calc(50% - #{scale-1200(15px)}) 1 0;
    }
  }
}
