.share-bar {
  --share-bar-border-color: #{$color-brand-gold};
  --share-bar-label-color: #{$color-brand-gold};
  --share-bar-icon-background: #{$color-brand-gold};
  --social-icon-fill: #{$color-brand-gold};

  display: flex;
  align-items: center;
  justify-content: flex-start;

  column-gap: fluid(
    $spacing-element-horizontal-min * 0.55,
    $spacing-element-horizontal-min * 0.83
  );

  row-gap: fluid(
    $spacing-element-horizontal-min * 0.55,
    $spacing-element-horizontal-min * 0.83
  );

  width: 100%;

  border-top: 1px solid var(--share-bar-border-color);
  padding-top: 15px;
}

.share-bar__label {
  @include cta--lg();
  color: var(--share-bar-label-color);
}

.share-bar__button {
  display: flex;
  align-items: center;
  column-gap: 10px;

  .c-icon {
    width: 30px;
    height: 30px;

    // &:not(.c-icon--social-lg) {
    //     border-radius: 50%;
    //     background-color: var(--share-bar-icon-background);
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    // }

    &.c-icon--share-pinterest {
      width: 24.1px;
      height: 24.1px;
    }

    &.c-icon--social {
      --social-icon-fill: transparent;
      color: var(--share-bar-icon-background);
    }

    &.c-icon--share {
      color: var(--social-icon-fill);
      margin-right: 0;

      display: flex;
      justify-content: center;

      svg {
        width: 26px;
        height: auto;
        // height: 12.17px;
      }

      &.c-icon--share-menu {
        margin-right: 0;

        svg {
          width: 11.91px;
          height: 16.45px;
          transform: translate3d(0, -2px, 0);
        }
      }

      &.c-icon--share-email {
        width: 33px;
        height: auto;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &.c-icon--share-print {
        width: 24px;
        height: auto;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.share-bar .share-menu {
  .addthis_toolbox {
    left: 0;
    top: 0;

    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: 10px;
    transform: translate3d(calc(-25% + 5px), -100%, 0);
  }

  &.active {
    .addthis_toolbox {
      display: flex;
    }
  }
}
